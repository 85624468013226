import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/role',
    name: 'role',
    component: () => import('@/views/role/Role.vue'),
    meta: {
      title: '角色管理'
    }
  },
  {
    path: '/role/add:orderNum',
    name: 'roleAdd',
    component: () => import('@/views/role/RoleAdd.vue'),
    meta: {
      title: '角色新增'
    }
  },
  {
    path: '/role/update:roleId',
    name: 'roleUpdate',
    component: () => import('@/views/role/RoleAdd.vue'),
    meta: {
      title: '角色编辑'
    }
  }

]

export default router
