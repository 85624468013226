import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/suppliermanage',
    name: 'suppliermanage',
    meta: {
      title: '供应商管理'
    },
    component: () => import('@/views/suppliermanage/List.vue')
  },
  {
    path: '/suppliermanage/add',
    name: 'addSuppliermanage',
    meta: {
      title: '新增供应商'
    },
    component: () => import('@/views/suppliermanage/Add.vue')
  },
  {
    path: '/suppliermanage/edit:supplierId',
    name: 'editSuppliermanage',
    meta: { title: '编辑供应商' },
    component: () => import('@/views/suppliermanage/Add.vue')
  },
  {
    path: '/suppliermanage/detail',
    name: 'detailSuppliermanage',
    meta: { title: '供应商详情' },
    component: () => import('@/views/suppliermanage/Detail.vue')
  }
]

export default router
