// const url = '/business/yhPlanReport/'
const url = '/business/yhPlanReport/'
const url1 = '/business/yhDailyPlan/'
export interface Apis {
  // 云养护
  /** 计划汇报/审核 */
  /** 新增 */
  insertYhPlanReport: string;
  /** 编辑 */
  updateYhPlanReport: string;
  /** 撤回操作、审核操作（批准、驳回） */
  updateStatusWithdraw: string;
  /** 查询详情 */
  selectYhPlanReportByReportId: string;
  /** 分页查询列表 */
  selectYhPlanReportByPage: string;
  /** 导入 */
  importYhPlanReport: string;
  /** 删除 */
  deleteYhPlanReport: string;

  // 新增新养护计划
  insertYhDailyPlan: string;
  // 修改新养护计划
  updateYhDailyPlan: string;
  // 删除新养护计划
  deleteYhDailyPlan: string;
  // 查询新养护计划详情
  selectYhDailyPlanByPlanId: string;
  // 分页查询新养护计划列表
  selectYhDailyPlanByPage: string;
  // 查询项目下最后一个计划详情
  selectUpYhDailyPlanByProjectId: string;
  // 查询新养护计划列表
  selectYhDailyPlanByList: string;
  // 查询计划任务关联的现场作业列表
  selectYhDailyWorkByList: string;
}

export const apis: Apis = {
  insertYhPlanReport: url + 'insertYhPlanReport',
  updateYhPlanReport: url + 'updateYhPlanReport',
  updateStatusWithdraw: url + 'updateStatusWithdraw',
  selectYhPlanReportByReportId: url + 'selectYhPlanReportByReportId',
  selectYhPlanReportByPage: url + 'selectYhPlanReportByPage',
  importYhPlanReport: url + 'importYhPlanReport',
  deleteYhPlanReport: url + 'deleteYhPlanReport',
  // 新
  insertYhDailyPlan: url1 + 'insertYhDailyPlan',
  updateYhDailyPlan: url1 + 'updateYhDailyPlan',
  deleteYhDailyPlan: url1 + 'deleteYhDailyPlan',
  selectYhDailyPlanByPlanId: url1 + 'selectYhDailyPlanByPlanId',
  selectYhDailyPlanByPage: url1 + 'selectYhDailyPlanByPage',
  selectUpYhDailyPlanByProjectId: url1 + 'selectUpYhDailyPlanByProjectId',
  selectYhDailyPlanByList: url1 + 'selectYhDailyPlanByList',
  selectYhDailyWorkByList: url1 + 'selectYhDailyWorkByList'
}
