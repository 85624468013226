// 项目文件
const url = '/business/yhProjectFiles/'
// 项目文件类型
const urls = '/business/yhProjectFileTypes/'
export interface Apis {
  /* 分页查询项目汇编列表 */
  selectYhProjectFilesCompilationByList: string;
  /* 新增项目文件 */
  insertYhProjectFiles: string;
  /* 删除项目文件 */
  deleteYhProjectFiles: string;
  /* 分页查询项目文件列表 */
  selectYhProjectFilesByPage: string;
  /* 新增项目文件类型 */
  insertYhProjectFileTypes: string;
  /* 修改项目文件类型 */
  updateYhProjectFileTypes: string;
  /* 删除项目文件类型 */
  deleteYhProjectFileTypes: string;
  /* 查询项目文件类型列表 */
  selectYhProjectFileTypesByList: string;
}
export const apis: Apis = {
  selectYhProjectFilesCompilationByList: url + 'selectYhProjectFilesCompilationByList',
  insertYhProjectFiles: url + 'insertYhProjectFiles',
  deleteYhProjectFiles: url + 'deleteYhProjectFiles',
  selectYhProjectFilesByPage: url + 'selectYhProjectFilesByPage',
  insertYhProjectFileTypes: urls + 'insertYhProjectFileTypes',
  updateYhProjectFileTypes: urls + 'updateYhProjectFileTypes',
  deleteYhProjectFileTypes: urls + 'deleteYhProjectFileTypes',
  selectYhProjectFileTypesByList: urls + 'selectYhProjectFileTypesByList'
}
