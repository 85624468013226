import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/utils/ajax'
import { apis } from '@/api'
import { Page } from '@/types/common'
import { ProjectDetail } from '@/types/project'
// import { UserState } from './modules/user'

Vue.use(Vuex)

export interface RootState {
  keepAlive: string[];
  projectList: ProjectDetail[];
  normalProjectList: ProjectDetail[];
  userInfo: object;
  messageNum: number;
  buttonList: Array<object>;
  menuList: Array<object>;
  MachineryInfo: object;
  menuIndex: number;
}

export default new Vuex.Store<RootState>({
  state: {
    keepAlive: [],
    projectList: [],
    normalProjectList: [],
    userInfo: JSON.parse(sessionStorage.getItem('userInfo') || '{}'),
    messageNum: 0,
    buttonList: [],
    menuList: JSON.parse(sessionStorage.getItem('menuList') || '{}'),
    MachineryInfo: {},
    menuIndex: +(sessionStorage.getItem('menuIndex') || 0)
  },
  mutations: {
    addKeepAlive (state: RootState, data: string) {
      if (!state.keepAlive.includes(data)) {
        state.keepAlive.push(data)
      }
    },
    removeKeepAlive (state: RootState, data: string) {
      const index = state.keepAlive.findIndex(item => item === data)
      state.keepAlive.splice(index, 1)
    },
    setProjectList (state: RootState, data: ProjectDetail[]) {
      state.projectList = data
    },
    setNormalProjectList (state: RootState, data: ProjectDetail[]) {
      state.normalProjectList = data
    },
    updateUserInfo (state: RootState, data) {
      state.userInfo = data
    },
    updateMessageNum (state: RootState, data: number) {
      state.messageNum = data
    },
    updateButtonList (state: RootState, data: Array<object>) {
      state.buttonList = data
    },
    updateMenuList (state: RootState, data: Array<object>) {
      state.menuList = data
    },
    updateMachineryInfo (state: RootState, data) {
      // console.log('diayo')
      state.MachineryInfo = data
    },
    updateMenuIndex (state: RootState, data) {
      // console.log('diayo')
      state.menuIndex = data
      sessionStorage.setItem('menuIndex', data.toString())
    }
  },
  actions: {
    getProjectList ({ commit }) {
      axios.get<Page<ProjectDetail>>(apis.project.selectYhProjectAllByList).then(res => {
        commit('setProjectList', res || [])
      })
    },
    getNormalProjectList ({ commit }) {
      axios.get<Page<ProjectDetail>>(apis.project.selectYhProjectByList).then(res => {
        commit('setNormalProjectList', res || [])
      })
    },
    // 更新登录账户信息
    getUserInfo ({ commit }) {
      axios.get(apis.userInfo.userInfo).then((res) => {
        window.sessionStorage.setItem('userInfo', JSON.stringify(res))
        commit('updateUserInfo', res)
      })
    },
    // 获取未读消息数
    getMessageNum ({ commit }, info) {
      axios.get(apis.userInfo.userReadNum, info).then((res) => {
        commit('updateMessageNum', res)
      })
    },
    // 获取菜单
    async getMenu ({ commit }) {
      await axios.get(apis.common.getMenu).then(res => {
        commit('updateMenuList', res)
        sessionStorage.setItem('menuList', JSON.stringify(res))
      })
    }
  },
  modules: {}
})
