import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/pestControl',
    name: 'pestControl',
    component: () => import('@/components/menuPage/Index.vue'),
    meta: {
      title: '病虫害防治'
    },
    children: [
      {
        path: 'overview',
        name: 'pestControlOverview',
        meta: {
          title: '概览',
          hasBack: false
        },
        component: () => import('@/components/overview/Index.vue')
      },
      {
        path: 'disease',
        name: 'disease',
        component: () => import('@/views/pestControl/Disease.vue'),
        meta: {
          title: '病害库',
          hasBack: false
        }
      },
      {
        path: 'insectPest',
        name: 'insectPest',
        component: () => import('@/views/pestControl/InsectPest.vue'),
        meta: {
          title: '虫害库',
          hasBack: false
        }
      },
      {
        path: 'insectMonitor',
        name: 'insectMonitor',
        props: true,
        component: () => import('@/views/ecologyMonitor/InsectMonitor.vue'),
        meta: {
          title: '智慧虫情',
          hasBack: false
        }
      }
    ]
  },
  // 新增病害
  {
    name: 'diseaseAdd',
    path: '/pestControl/disease/add',
    component: () => import('@/views/pestControl/DiseaseAdd.vue'),
    meta: {
      title: '新增'
    }
  },
  // 编辑病害
  {
    name: 'diseaseEdit',
    path: '/pestControl/disease/edit:diseasesId',
    component: () => import('@/views/pestControl/DiseaseAdd.vue'),
    meta: {
      title: '编辑'
    }
  },
  // 病害详情
  {
    name: 'diseaseDetail',
    path: '/pestControl/disease/detail:diseasesId',
    component: () => import('@/views/pestControl/DiseaseDetail.vue'),
    meta: {
      title: '详情'
    }
  },
  // 新增虫害
  {
    name: 'insectPestAdd',
    path: '/pestControl/insectPest/add',
    component: () => import('@/views/pestControl/InsectPestAdd.vue'),
    meta: {
      title: '新增'
    }
  },
  // 编辑虫害
  {
    name: 'insectPestEdit',
    path: '/pestControl/insectPest/edit:pestsId',
    component: () => import('@/views/pestControl/InsectPestAdd.vue'),
    meta: {
      title: '编辑'
    }
  },
  // 虫害详情
  {
    name: 'insectPestDetail',
    path: '/pestControl/insectPest/detail:pestsId',
    component: () => import('@/views/pestControl/InsectPestDetail.vue'),
    meta: {
      title: '详情'
    }
  },
  // 智慧虫情历史数据
  {
    name: 'insectHistory',
    path: '/pestControl/insectMonitor/insectHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/InsectHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  // 智慧虫情历史监测
  {
    name: 'insectMonitorHistory',
    path: '/pestControl/insectMonitor/insectMonitorHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/InsectMonitorHistory.vue'),
    meta: {
      title: '历史监测'
    }
  }
]

export default router
