// 农事日历列表
const url = '/business/yhFarmingCalendar/'
// 农事日历作业
const urls = '/business/yhFarmingJob/'
// 农事指导
const urlx = '/business/yhFarmingCalendarTemplate/'
export interface Apis {

  // 云养护
  /** 农事日历列表 */
  selectYhFarmingCalendarList: string;
  /** 农事日历详情 */
  selectYhFarmingCalendar: string;
  /** 添加农事日历作业 */
  insertYhFarmingJob: string;
  /** 修改农事日历作业 */
  updateYhFarmingJob: string;
  /** 删除农事日历作业 */
  deleteYhFarmingJob: string;
  /** 修改农事日历作业状态 */
  updateYhFarmingJobStatus: string;
  /** 初始化农事指导列表 */
  addAllCalendarTemplate: string;
  /** 初始化农事日历列表 */
  addAllCalendar: string;
  /** 获取农事指导列表 */
  selectYhFarmingCalendarTemplateByPage: string;
  /** 查询农事指导详情 */
  selectYhFarmingCalendarTemplate: string;
  /** 编辑农事指导 */
  updateYhFarmingCalendarTemplate: string;
}
export const apis: Apis = {

  selectYhFarmingCalendarList: url + 'selectYhFarmingCalendarList',
  selectYhFarmingCalendar: url + 'selectYhFarmingCalendar',
  insertYhFarmingJob: urls + 'insertYhFarmingJob',
  updateYhFarmingJob: urls + 'updateYhFarmingJob',
  deleteYhFarmingJob: urls + 'deleteYhFarmingJob',
  updateYhFarmingJobStatus: urls + 'updateYhFarmingJobStatus',
  updateYhFarmingCalendarTemplate: urlx + 'updateYhFarmingCalendarTemplate',
  selectYhFarmingCalendarTemplate: urlx + 'selectYhFarmingCalendarTemplate',
  selectYhFarmingCalendarTemplateByPage: urlx + 'selectYhFarmingCalendarTemplateByPage',
  addAllCalendarTemplate: urlx + 'addAllCalendarTemplate',
  addAllCalendar: urlx + 'addAllCalendar'
}
