export interface Apis {
  recordPage: string;

  // 操作日志
  selectYhLogByPage: string;
}

export const apis: Apis = {
  recordPage: '/mc/msg/selectRecordPage',
  selectYhLogByPage: '/msg/YhLog/selectYhLogByPage'
}
