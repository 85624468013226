import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/earlyWarning',
    name: 'earlyWarning',
    component: () => import('@/views/earlyWarning/List.vue'),
    meta: {
      title: '预警监控中心'
    }
  },
  {
    path: '/earlyWarning/detail',
    name: 'earlyWarningDetail',
    component: () => import('@/views/earlyWarning/Detail.vue'),
    meta: {
      title: '详情'
    }
  }
]

export default router
