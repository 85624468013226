import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/department',
    name: 'department',
    component: () => import('@/views/department/Department.vue'),
    meta: {
      title: '组织架构'
    }
  },
  {
    path: '/department/add',
    name: 'personnelAdd',
    component: () => import('@/views/department/PersonnelAdd.vue'),
    meta: {
      title: '新增人员'
    }
  },
  {
    path: '/department/update:id',
    name: 'personnelUpdate',
    component: () => import('@/views/department/PersonnelAdd.vue'),
    meta: {
      title: '编辑人员'
    }
  },
  {
    path: '/department/detail:id',
    name: 'personnelDetail',
    component: () => import('@/views/department/PersonnelDetail.vue'),
    meta: {
      title: '人员详情'
    }
  }
]

export default router
