// 项目管理
import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/projectList',
    name: 'projectList',
    props: true,
    component: () => import('@/views/project/List.vue'),
    meta: {
      title: '项目信息',
      hasBack: false
    }
  },
  {
    path: '/projectList/add',
    name: 'projectAdd',
    component: () => import('@/views/project/Add.vue'),
    meta: {
      title: '添加项目'
    }
  },
  {
    path: '/projectList/detail',
    name: 'projectDetail',
    component: () => import('@/views/project/Detail.vue'),
    redirect: '/projectList',
    children: [
      {
        path: 'base',
        component: () => import('@/views/project/Base.vue'),
        meta: {
          title: '项目详情'
        }
      },
      {
        path: 'person',
        component: () => import('@/views/project/Person.vue'),
        meta: {
          title: '项目详情'
        }
      },
      {
        path: 'area',
        component: () => import('@/views/project/AreaSet.vue'),
        meta: {
          title: '项目详情'
        }
      },
      {
        path: 'subzone',
        component: () => import('@/views/project/SubZone.vue'),
        meta: {
          title: '项目分区'
        }
      },
      {
        path: 'owner',
        component: () => import('@/views/project/Owner.vue'),
        meta: {
          title: '项目详情'
        }
      },
      {
        path: 'subzone/add',
        component: () => import('@/views/project/AddArea.vue'),
        meta: {
          title: '新增区域',
          hasQuery: true,
          backUrl: '/projectList/detail/subzone'
        }
      }
    ]
  }
]

export default router
