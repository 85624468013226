
import { Component, Vue, Watch } from 'vue-property-decorator'
import { MenuItem } from '@/types/menu'

@Component({
  name: 'TopMenu'
})
export default class TopMenu extends Vue {
  private active = ''
  private menuList: any = []

  @Watch('$route')
  routeChange (val: any) {
    console.log('路径变化', val.path)
    if (this.menuList.length > 0) {
      sessionStorage.removeItem('childNode')
      this.firstEnter()
      this.getButtonList(this.menuList)
    }
  }

  async created () {
    await this.$store.dispatch('getMenu')
    this.menuList = this.$store.state.menuList
    this.firstEnter()
    this.getButtonList(this.menuList)
  }

  firstEnter () {
    // 第一次默认进入第一个菜单
    const { path } = this.$route
    if (path === '/') {
      this.clickMenu(0)
    }
    this.setActive()
  }

  setActive () {
    const activePath = '/' + this.$route.path.split('/')[1] || ''
    const index = this.menuList.findIndex((item: any) => JSON.stringify(item).indexOf(activePath) !== -1)
    this.active = '/' + this.menuList[index].url.split('/')[1] || ''
  }

  getButtonList (menuList: Array<MenuItem>) {
    const index = '/' + this.$route.path.split('/')[1]
    const indexs = '/' + this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
    menuList.map((item: MenuItem) => {
      if (item.childNode) {
        if (item.url === index) {
          // 四级菜单缓存
          sessionStorage.setItem('childNode', JSON.stringify(item))
        }
        this.getButtonList(item.childNode)
      } else if (item.url === index || item.url === indexs) {
        this.$store.commit('updateButtonList', item.button ? item.button : [])
      }
    })
  }

  clickMenu (menuIndex: number) {
    this.$store.commit('updateMenuIndex', menuIndex)
    // 默认进入最后一层页面
    const path = this.menuList[menuIndex].childNode ? this.menuList[menuIndex].childNode[0].childNode ? this.menuList[menuIndex].childNode[0].childNode[0].url : this.menuList[menuIndex].childNode[0].url : this.menuList[menuIndex].url
    setTimeout(() => {
      this.$router.push({
        path: path
      })
    })
  }
}
