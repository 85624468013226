// 数据处理
interface ObjectType {
  [key: string]: string | number | null;
}

// 去除对象空元素
export const clearObjEmptyPrama = (obj: ObjectType) => {
  for (const key in obj) {
    // 去除空格
    if (typeof obj[key] === 'string') {
      obj[key] = String(obj[key]).trim()
    }
    if (Object.prototype.hasOwnProperty.call(obj, key) && (obj[key] !== 0) && (obj[key] === null || obj[key] === '')) {
      delete obj[key]
    }
  }
  return obj
}

// 根据身份证号计算年龄与性别
export const analyzeIDCard = (IDCard: string) => {
  const sexAndAge: { sex: string; age: string } = {
    sex: '',
    age: ''
  }

  // 获取身份证号码
  const userCard = IDCard
  // 如果身份证号码为undefind则返回空
  if (userCard && (userCard.length === 15 || userCard.length === 18)) {
    const startNum = userCard.length === 15 ? 8 : 10
    // 获取性别 '0': 男, '1': 女
    if (parseInt(userCard.substr(startNum + 6, 1)) % 2 === 1) {
      sexAndAge.sex = '0'
    } else {
      sexAndAge.sex = '1'
    }

    // 获取出生年月日
    const yearBirth = Number(userCard.substring(6, startNum))
    const monthBirth = Number(userCard.substring(startNum, startNum + 2))
    const dayBirth = Number(userCard.substring(startNum + 2, startNum + 4))
    // 获取当前年月日并计算年龄
    const myDate = new Date()
    const monthNow = Number(myDate.getMonth() + 1)
    const dayNow = Number(myDate.getDay())
    let age = Number(myDate.getFullYear() - yearBirth)
    if (monthNow < monthBirth || (monthNow === monthBirth && dayNow < dayBirth)) {
      age--
    }
    // 得到年龄
    sexAndAge.age = age.toString()
  }

  // 返回性别和年龄
  return sexAndAge
}

// 获取uuid
export const getUUID = () => {
  // 32长度
  const len = 32
  // 16进制
  const radix = 16
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  let i
  for (i = 0; i < len; i++) {
    uuid[i] = chars[0 | Math.random() * radix]
  }
  return uuid.join('')
}

// 风向转换
export const directionTransition = (windDeg: number) => {
  if (windDeg) {
    if (windDeg <= 22.25 || windDeg > 337.75) {
      return '北风'
    } else if (windDeg > 22.25 && windDeg <= 67.25) {
      return '东北风'
    } else if (windDeg > 67.25 && windDeg <= 112.25) {
      return '东风'
    } else if (windDeg > 112.25 && windDeg <= 157.25) {
      return '东南风'
    } else if (windDeg > 157.25 && windDeg <= 202.25) {
      return '南风'
    } else if (windDeg > 202.25 && windDeg <= 247.25) {
      return '西南风'
    } else if (windDeg > 247.25 && windDeg <= 292.25) {
      return '西风'
    } else if (windDeg > 292.25 && windDeg <= 337.25) {
      return '西北风'
    } else {
      return '--'
    }
  } else {
    return '--'
  }
}

// 判断某个元素是否在数组中
export const isInArray = (arr: Array<any>, value: string) => {
  for (let i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true
    }
  }
  return false
}

// 电子围栏样式
interface PolygonStyle {
  fillColor: string; // 背景色
  fillOpacity?: number; // 背景透明
  strokeWeight?: number; // 线条宽度
  strokeColor: string; // 线条色
  clickable?: boolean; // 是否可点击
  [propName: string]: any;
}

// 判断某个元素是否在数组中 map: 地图，list：电子围栏数组，style：样式，传null时用默认，clickAction：点击事件监听，没有传null
export const drawPolygon = (map: AMap.Map, list: Array<{ longitude: number; latitude: number; fenceCode: string }>, style: PolygonStyle | null, clickAction?: any) => {
  if (list && list.length > 0) {
    const _fence: Array<any> = []
    const ploygons: Array<AMap.Polygon> = []
    list.forEach(item => {
      if (!isInArray(_fence, item.fenceCode)) {
        _fence.push(item.fenceCode)
      }
    })
    _fence.forEach(fItem => {
      const path: Array<any> = []
      list.forEach(pItem => {
        if (fItem === pItem.fenceCode) {
          path.push(new AMap.LngLat(pItem.longitude, pItem.latitude))
        }
      })
      const polygonStyle = style ? { ...style } : {
        fillColor: 'rgb(54, 203, 203)', // 背景色
        fillOpacity: 0.3, // 背景透明
        strokeWeight: 4, // 线条宽度
        strokeColor: 'rgba(54, 203, 203, 1)', // 线条色
        clickable: true // 是否可点击
      }
      const polygon = new AMap.Polygon({
        map: map,
        path: path,
        ...polygonStyle,
        extData: { fenceCode: fItem || '' }
      })
      map && map.add(polygon)
      // 点击电子围栏方法
      if (clickAction) {
        polygon.on('click', clickAction)
      }
      ploygons.push(polygon)
    })
    console.log('drawPolygon Function')
    // 缩放地图到合适的视野级别
    map && map.setFitView(ploygons)
    return ploygons
  } else {
    return []
  }
}

// 三位数字前加逗号分隔

export const toThousands = (str: string) => {
  if (!str || str === 'null' || str === 'undefined') {
    return 0
  }
  let num = str.toString().split('.')[0]
  let result = ''; let counter = 0
  num = (num || 0).toString()
  for (let i = num.length - 1; i >= 0; i--) {
    counter++
    result = num.charAt(i) + result
    if (!(counter % 3) && i !== 0) { result = ',' + result }
  }
  return result
}

// 时期时间补0（后台会将00:00:00过滤掉）
export const timeCompletion = (str: string) => {
  if (str.length === 10) {
    return str + ' 00:00:00' // 注意空格
  } else {
    return str
  }
}

// 去除空格和特殊字符
export const removeSpecial = (str: string) => {
  const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）—|{}【】‘；：”“'。，、？%+_-]", 'g')
  return str.replace(/\s/g, '').replace(pattern, '')
}

// 启动全屏!
export const launchFullscreen = (element: any) => {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  }
}

// 退出全屏模式!
export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  }
}

// 随机获取颜色
export const colorRandom = () => {
  let color = '#'
  const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
  for (let i = 0; i < 6; i++) {
    const num = parseInt((Math.random() * 16).toString())
    color += arr[num]
  }
  return color
}

export function getPolygons (items = []) {
  let array: any = []
  if (items && items.length > 0) {
    const map = new Map()
    items.forEach((item: any) => {
      if (map.has(item.fenceCode)) {
        map.get(item.fenceCode).push({
          latitude: +item.latitude,
          longitude: +item.longitude
        })
      } else {
        map.set(item.fenceCode, [{
          latitude: +item.latitude,
          longitude: +item.longitude
        }])
      }
    })
    array = Array.from(map.entries())
  }
  return array
}

export const adjustScale = () => {
  const clientWidth = document.documentElement.clientWidth
  const clientHeight = document.documentElement.clientHeight
  const scaleX = clientWidth / 1920
  const scaleY = clientHeight / 1080
  return {
    scaleX,
    scaleY
  }
}
