import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/recruitUse',
    name: 'recruitUse',
    component: () => import('@/components/menuPage/Index.vue'),
    meta: {
      title: '人员智能调度'
    },
    children: [
      {
        path: 'overview',
        name: 'overview',
        meta: {
          title: '概览',
          hasBack: false
        },
        component: () => import('@/components/overview/Index.vue')
      },
      {
        path: 'workcard',
        name: 'workcard',
        component: () => import('@/views/recruitUse/List.vue'),
        meta: {
          title: '智能监测',
          hasBack: false
        }
      },
      {
        path: 'scheduleManage',
        name: 'scheduleManage',
        props: true,
        component: () => import('@/views/recruitUse/ScheduleManage.vue'),
        meta: {
          title: '排班管理',
          hasBack: false
        }
      },
      {
        path: 'onlineStatistics',
        name: 'onlineStatistics',
        props: true,
        component: () => import('@/views/recruitUse/OnlineStatistics.vue'),
        meta: {
          title: '在岗统计',
          hasBack: false
        }
      }
    ]
  },
  {
    name: 'scheduleListAdd',
    path: '/recruitUse/scheduleManage/add',
    component: () => import('@/views/recruitUse/ScheduleAdd.vue'),
    meta: {
      title: '添加排班'
    }
  },
  {
    name: 'scheduleListEdit',
    path: '/recruitUse/scheduleManage/edit:recordId',
    component: () => import('@/views/recruitUse/ScheduleAdd.vue'),
    meta: {
      title: '编辑排班'
    }
  },
  {
    name: 'ScheduleDetail',
    path: '/recruitUse/scheduleManage/detail:recordId',
    component: () => import('@/views/recruitUse/ScheduleDetail.vue'),
    meta: {
      title: '详情'
    }
  },
  // 在岗位则详情页
  {
    path: '/recruitUse/onlineStatistics/rulesettings',
    name: 'rulesettings',
    meta: { title: '详情' },
    component: () => import('@/views/recruitUse/RuleSettings.vue')
  },
  // 智能监测详情页
  {
    path: '/recruitUse/workcard/workcardDetail:projectId',
    name: 'workcardDetail',
    meta: {
      title: '详情'
    },
    redirect: '/recruitUse/workcard/workcardDetail:projectId/map',
    component: () => import('@/views/recruitUse/Detail.vue'),
    children: [
      {
        path: 'map',
        name: 'workcardDetailMap',
        meta: {
          title: '详情',
          backUrl: '/recruitUse/workcard'
        },
        component: () => import('@/views/recruitUse/DetailMap.vue')
      },
      {
        path: 'list',
        name: 'workcardDetailList',
        meta: {
          title: '详情',
          backUrl: '/recruitUse/workcard'
        },
        component: () => import('@/views/recruitUse/DetailList.vue')
      }
    ]
  },
  {
    path: '/recruitUse/workcard/workcardDetail:projectId/map/playback:workerId',
    name: 'recruitUseMapPlayback',
    meta: {
      title: '轨迹回放'
    },
    component: () => import('@/views/recruitUse/DetailPlayback.vue')
  },
  {
    path: '/recruitUse/workcard/workcardDetail:projectId/list/playback:workerId',
    name: 'recruitUseListPlayback',
    meta: {
      title: '轨迹回放'
    },
    component: () => import('@/views/recruitUse/DetailPlayback.vue')
  },
  {
    path: '/recruitUse/onlineStatistics/detail',
    name: 'onlineStatisticsDetail',
    meta: { title: '详情' },
    component: () => import('@/views/recruitUse/OnlineStatisticsDetail.vue')
  }
]

export default router
