// 割草机
const mowlUrl = '/business/mowingInfo/'
export interface Apis {
  /* 割草机 */
  // 分页查询割草机列表
  selectMowingInfoByPage: string;
  // 批量分配割草机
  updateMowingInfo: string;
  // 查询割草机项目列表
  selectProjectMowingByList: string;
  // 查询割草机详情
  selectMowingInfoByDeviceNumber: string;
  // 修改割草机信息
  updateMowingInfoLocation: string;
  // 发送割草机指令
  insertMowingInstructions: string;
  // 分页查询割草机割草记录
  selectMowingDataByList: string;
  // 同步时间
  insertMowingTime: string;
  // 同步数据
  insertMowingData: string;
  // 割草机时间段配置
  insertMowingCuttime: string;
}

export const apis: Apis = {
  selectMowingInfoByPage: mowlUrl + 'selectMowingInfoByPage',
  updateMowingInfo: mowlUrl + 'updateMowingInfo',
  selectProjectMowingByList: mowlUrl + 'selectProjectMowingByList',
  selectMowingInfoByDeviceNumber: mowlUrl + 'selectMowingInfoByDeviceNumber',
  updateMowingInfoLocation: mowlUrl + 'updateMowingInfoLocation',
  insertMowingInstructions: mowlUrl + 'insertMowingInstructions',
  selectMowingDataByList: mowlUrl + 'selectMowingDataByList',
  insertMowingTime: mowlUrl + 'insertMowingTime',
  insertMowingData: mowlUrl + 'insertMowingData',
  insertMowingCuttime: mowlUrl + 'insertMowingCuttime'
}
