import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/handover',
    name: 'handover',
    meta: {
      title: '项目移交'
    },
    component: () => import('@/components/menuPage/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'handoverList',
        meta: {
          title: '移交清单',
          hasBack: false
        },
        component: () => import('@/views/handover/HandoverList.vue')
      },
      {
        path: 'matterLog',
        name: 'handoverMatterLog',
        meta: {
          title: '移交事项记录',
          hasBack: false
        },
        component: () => import('@/views/handover/HandoverMatter.vue')
      }
    ]
  },
  {
    path: '/handover/matterLog/detail:issueId',
    name: 'HandoverMatterDetail',
    meta: {
      title: '移交事项详情'
    },
    component: () => import('@/views/handover/HandoverMatterDetail.vue')
  }
]

export default router
