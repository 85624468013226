import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/message',
    name: 'message',
    meta: {
      title: '消息中心'
    },
    redirect: '/message/warning',
    component: () => import('@/views/message/Message.vue'),
    children: [
      {
        path: 'warning',
        name: 'warning',
        props: true,
        component: () => import('@/views/message/Warning.vue'),
        meta: {
          title: '消息预警',
          hasBack: false
        }
      },
      {
        path: 'record',
        name: 'record',
        component: () => import('@/views/message/Record.vue'),
        meta: {
          title: '消息记录',
          hasBack: false
        }
      }
    ]
  },
  {
    path: 'message/messageList/detail:id',
    name: 'warningDetail',
    component: () => import('@/views/message/WarningDetail.vue'),
    meta: {
      title: '消息详情'
    }
  },
  {
    path: '/operationLog',
    name: 'operationLog',
    component: () => import('@/views/message/OperationLog.vue'),
    meta: {
      title: '操作日志'
    }
  }
]

export default router
