import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/preBidCalc',
    name: 'preBidCalc',
    meta: {
      title: '标前测算'
    },
    component: () => import('@/components/menuPage/Index.vue'),
    children: [
      {
        path: 'calcList',
        name: 'preBidCalcList',
        meta: {
          title: '测算列表',
          hasBack: false
        },
        component: () => import('@/views/preBidCalc/CalcList.vue')
      },
      {
        path: 'quotaLibrarySet',
        name: 'preBidCalcQuotaLibrarySet',
        meta: {
          title: '定额库设置',
          hasBack: false
        },
        component: () => import('@/views/preBidCalc/QuotaLibrarySet.vue')
      }
    ]
  },
  // 新增测算
  {
    path: '/preBidCalc/calcList/add',
    name: 'preBidCalcAdd',
    component: () => import('@/views/preBidCalc/CalcListAdd.vue'),
    meta: {
      title: '新增'
    }
  },
  // 测算详情
  {
    path: '/preBidCalc/calcList/detail:id',
    name: 'preBidCalcDetail',
    component: () => import('@/views/preBidCalc/CalcListDetail.vue'),
    meta: {
      title: '详情'
    }
  }
]

export default router
