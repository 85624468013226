// 劳务管理
const teamUrl = '/business/yhTeamManagement/' // 班组
const workerAttendanceUrl = '/business/yhSingleAttendance/' // 工人考勤
const teamsAttendanceUrl = '/business/yhTeamAttendance/' // 班组考勤
const extraworkAttendanceUrl = '/business/yhOvertime/' // 加班记录
const taiBanUrl = '/business/yhMachineTeam/' // 台班劳务
const yhAttendanceCountUrl = '/business/yhAttendanceCount/' // 台班劳务
export interface Apis {
  // 班组
  // 分页查询班组管理表列表
  selectYhTeamByPage: string;
  // 新增
  insertYhTeam: string;
  // 修改
  updateYhTeam: string;
  // 删除
  deleteDataYhTeam: string;
  // 启用/禁用班组
  forbiddenYhTeam: string;
  // 查询班组管理表详情
  selectYhTeamByTeamId: string;

  // 工人考勤
  // 分页查询工人单人考勤列表
  selectYhSingleAttendanceByPage: string;
  // 查询工人单人考情详情
  selectYhSingleAttendanceDetail: string;

  // 班组考勤
  // 分页查询班组考勤列表
  selectYhTeamAttendanceByPage: string;
  // 查询工人单人考情详情
  selectYhTeamAttendanceDetail: string;
  // 修正班组签到人数
  updateTeamSignNum: string;
  // 分页查询班组考勤纠偏记录列表
  selectYhTeamSignModifyLogByPage: string;
  // 查询班组考勤纠偏记录详情
  selectYhTeamSignModifyLogByModifyId: string;

  // 加班记录
  // 分页查询加班记录列表
  selectOvertimeRecordByPage: string;
  // 新增加班时校验
  verifyYhOvertime: string;
  // 新增加班
  insertYhOvertime: string;
  // 更新加班记录
  updateYhOvertime: string;
  // 删除加班记录
  deleteYhOvertime: string;
  // 查询加班记录详情
  selectYhOvertimeRecordDetailByOvertimeId: string;
  // web考勤统计
  selectYhAttendanceCountByWeb: string;

  // 分页查询台班劳务列表
  selectYhMachineTeamByPage: string;
  // 新增台班劳务
  insertYhMachineTeam: string;
  // 修改
  updateYhMachineTeam: string;
  // 删除
  deleteYhMachineTeam: string;
  // 详情
  selectYhMachineTeamByMachineTeamId: string;

  // 查询机械列表
  selectYhMachineryByList: string;

}
export const apis: Apis = {
  selectYhTeamByPage: teamUrl + 'selectYhTeamByPage',
  selectYhTeamByTeamId: teamUrl + 'selectYhTeamByTeamId',
  insertYhTeam: teamUrl + 'insertYhTeam',
  updateYhTeam: teamUrl + 'updateYhTeam',
  deleteDataYhTeam: teamUrl + 'deleteDataYhTeam',
  forbiddenYhTeam: teamUrl + 'forbiddenYhTeam',
  selectYhSingleAttendanceByPage: workerAttendanceUrl + 'selectYhSingleAttendanceByPage',
  selectYhSingleAttendanceDetail: workerAttendanceUrl + 'selectYhSingleAttendanceDetail',
  selectYhTeamAttendanceByPage: teamsAttendanceUrl + 'selectYhTeamAttendanceByPage',
  selectYhTeamAttendanceDetail: teamsAttendanceUrl + 'selectYhTeamAttendanceDetail',
  updateTeamSignNum: teamsAttendanceUrl + 'updateTeamSignNum',
  selectYhTeamSignModifyLogByPage: teamsAttendanceUrl + 'selectYhTeamSignModifyLogByPage',
  selectYhTeamSignModifyLogByModifyId: teamsAttendanceUrl + 'selectYhTeamSignModifyLogByModifyId',
  selectOvertimeRecordByPage: extraworkAttendanceUrl + 'selectOvertimeRecordByPage',
  verifyYhOvertime: extraworkAttendanceUrl + 'verifyYhOvertime',
  insertYhOvertime: extraworkAttendanceUrl + 'insertYhOvertime',
  updateYhOvertime: extraworkAttendanceUrl + 'updateYhOvertime',
  deleteYhOvertime: extraworkAttendanceUrl + 'deleteYhOvertime',
  selectYhOvertimeRecordDetailByOvertimeId: extraworkAttendanceUrl + 'selectYhOvertimeRecordDetailByOvertimeId',
  selectYhAttendanceCountByWeb: yhAttendanceCountUrl + 'selectYhAttendanceCountByWeb',
  selectYhMachineTeamByPage: taiBanUrl + 'selectYhMachineTeamByPage',
  insertYhMachineTeam: taiBanUrl + 'insertYhMachineTeam',
  updateYhMachineTeam: taiBanUrl + 'updateYhMachineTeam',
  deleteYhMachineTeam: taiBanUrl + 'deleteYhMachineTeam',
  selectYhMachineTeamByMachineTeamId: taiBanUrl + 'selectYhMachineTeamByMachineTeamId',
  selectYhMachineryByList: '/business/yhMachinery/selectYhMachineryByList'
}
