const buildUrl = '/business/building/'
const roomUrl = '/business/room/'
const soilUrl = '/business/sqSoilInfo/'
const waterUrl = '/business/sqSqWaterMeter/'
const irriagateDeviceUrl = '/business/sqIrrigationDevice/'
const irriagatePlanUrl = '/business/sqIrrigationPlan/'
const sqWarningUrl = '/business/sqWarning/'
const sqStatisticsUrl = '/business/sqStatistics/'
export interface Apis {
  // 概览页
  // 设备统计
  selectDeviceTotal: string;
  // 房间统计
  selectRoomTotal: string;
  // 用水统计
  selectWaterTotal: string;
  // 用水折现图
  selectWaterLine2: string;
  // 楼栋管理
  // 新增
  insertBuilding: string;
  // 修改
  updateBuilding: string;
  // 删除
  deleteBuilding: string;
  // 查询楼栋信息详情
  selectBuildingByBuildingId: string;
  // 查询楼栋信息列表
  selectBuildingByList: string;
  // 房间
  // 新增
  insertRoom: string;
  // 修改
  updateRoom: string;
  // 删除
  deleteRoom: string;
  // 绑定设备
  updateRoomDevice: string;
  // 绑定房间业主
  updateRoomUser: string;
  // 查询房间信息详情
  selectRoomByRoomId: string;
  // 查询房间信息列表
  selectRoomByList: string;
  // 设备管理
  // 土壤
  // 修改土壤监测配置
  updateSqSoilInfoConf: string;
  // 查询土壤监测详情
  selectSqSoilInfoByDeviceNumber: string;
  // 分页查询土壤监测列表
  selectSqSoilInfoByPage: string;
  // 分页查询土壤监测历史列表
  selectSqSoilDataByPage: string;
  // 水表
  // 修改水表配置
  updateSqWaterMeterInfoConf: string;
  // 查询水表详情
  selectSqWaterMeterInfoByDeviceNumber: string;
  // 分页查询水表列表
  selectSqWaterMeterInfoByPage: string;
  // 分页查询水表历史列表
  selectSqWaterMeterDataByPage: string;
  // 每日用水折线图
  selectWaterLine: string;
  // 电磁阀
  // 喷灌设备
  // 喷灌电磁阀修改名称
  updateSqIrrigationValveName: string;
  // 手动开关电磁阀
  insertSqIrrigationValveRecord: string;
  // 分页查询喷灌电磁阀列表
  selectSqIrrigationValveByPage: string;
  // 查询电磁阀楼栋视图
  selectSqIrrigationValveBuildingByList: string;
  // 查询电磁阀楼栋详情
  selectSqIrrigationValveBuildingById: string;
  // 查询喷灌电磁阀详情
  selectSqIrrigationValveByNumber: string;
  // 分页查询电磁阀执行记录列表
  selectSqIrrigationValveRecordByPage: string;
  // 喷灌计划
  // 新增喷灌计划
  insertSqIrrigationPlan: string;
  // 修改喷灌计划
  updateSqIrrigationPlan: string;
  // 启用/禁用喷灌计划
  updateSqIrrigationPlanStatus: string;
  // 删除喷灌计划
  deleteSqIrrigationPlan: string;
  // 批量删除喷灌计划
  deleteAllSqIrrigationPlan: string;
  // 查询喷灌计划详情
  selectSqIrrigationPlanByPlanId: string;
  // 分页查询喷灌计划列表
  selectSqIrrigationPlanByPage: string;
  // 告警
  selectSqWarningByPage: string;
  // 实时监测
  // 喷灌任务
  // 喷灌计划
}

export const apis: Apis = {
  // 概览页
  // 设备统计
  selectDeviceTotal: sqStatisticsUrl + 'selectDeviceTotal',
  // 房间统计
  selectRoomTotal: sqStatisticsUrl + 'selectRoomTotal',
  // 用水统计
  selectWaterTotal: sqStatisticsUrl + 'selectWaterTotal',
  // 用水折现图
  selectWaterLine2: sqStatisticsUrl + 'selectWaterLine',
  // 楼栋管理
  // 新增
  insertBuilding: buildUrl + 'insertBuilding',
  // 修改
  updateBuilding: buildUrl + 'updateBuilding',
  // 删除
  deleteBuilding: buildUrl + 'deleteBuilding',
  // 查询楼栋信息详情
  selectBuildingByBuildingId: buildUrl + 'selectBuildingByBuildingId',
  // 查询楼栋信息列表
  selectBuildingByList: buildUrl + 'selectBuildingByList',
  // 房间
  // 新增
  insertRoom: roomUrl + 'insertRoom',
  // 修改
  updateRoom: roomUrl + 'updateRoom',
  // 删除
  deleteRoom: roomUrl + 'deleteRoom',
  // 绑定设备
  updateRoomDevice: roomUrl + 'updateRoomDevice',
  // 绑定房间业主
  updateRoomUser: roomUrl + 'updateRoomUser',
  // 查询房间信息详情
  selectRoomByRoomId: roomUrl + 'selectRoomByRoomId',
  // 查询房间信息列表
  selectRoomByList: roomUrl + 'selectRoomByList',
  // 设备管理
  // 土壤
  // 修改土壤监测配置
  updateSqSoilInfoConf: soilUrl + 'updateSqSoilInfoConf',
  // 查询土壤监测详情
  selectSqSoilInfoByDeviceNumber: soilUrl + 'selectSqSoilInfoByDeviceNumber',
  // 分页查询土壤监测列表
  selectSqSoilInfoByPage: soilUrl + 'selectSqSoilInfoByPage',
  // 分页查询土壤监测历史列表
  selectSqSoilDataByPage: soilUrl + 'selectSqSoilDataByPage',
  // 水表
  // 修改水表配置
  updateSqWaterMeterInfoConf: waterUrl + 'updateSqWaterMeterInfoConf',
  // 查询水表详情
  selectSqWaterMeterInfoByDeviceNumber: waterUrl + 'selectSqWaterMeterInfoByDeviceNumber',
  // 分页查询水表列表
  selectSqWaterMeterInfoByPage: waterUrl + 'selectSqWaterMeterInfoByPage',
  // 分页查询水表历史列表
  selectSqWaterMeterDataByPage: waterUrl + 'selectSqWaterMeterDataByPage',
  //
  selectWaterLine: waterUrl + 'selectWaterLine',
  // 电磁阀
  // 喷灌设备
  // 喷灌电磁阀修改名称
  updateSqIrrigationValveName: irriagateDeviceUrl + 'updateSqIrrigationValveName',
  // 手动开关电磁阀
  insertSqIrrigationValveRecord: irriagateDeviceUrl + 'insertSqIrrigationValveRecord',
  // 分页查询喷灌电磁阀列表
  selectSqIrrigationValveByPage: irriagateDeviceUrl + 'selectSqIrrigationValveByPage',
  // 查询电磁阀楼栋视图
  selectSqIrrigationValveBuildingByList: irriagateDeviceUrl + 'selectSqIrrigationValveBuildingByList',
  // 查询电磁阀楼栋详情
  selectSqIrrigationValveBuildingById: irriagateDeviceUrl + 'selectSqIrrigationValveBuildingById',
  // 分页查询电磁阀执行记录列表
  selectSqIrrigationValveRecordByPage: irriagateDeviceUrl + 'selectSqIrrigationValveRecordByPage',
  // 查询喷灌电磁阀详情
  selectSqIrrigationValveByNumber: irriagateDeviceUrl + 'selectSqIrrigationValveByNumber',

  // 喷灌计划
  // 新增喷灌计划
  insertSqIrrigationPlan: irriagatePlanUrl + 'insertSqIrrigationPlan',
  // 修改喷灌计划
  updateSqIrrigationPlan: irriagatePlanUrl + 'updateSqIrrigationPlan',
  // 启用/禁用喷灌计划
  updateSqIrrigationPlanStatus: irriagatePlanUrl + 'updateSqIrrigationPlanStatus',
  // 删除喷灌计划
  deleteSqIrrigationPlan: irriagatePlanUrl + 'deleteSqIrrigationPlan',
  // 批量删除
  deleteAllSqIrrigationPlan: irriagatePlanUrl + 'deleteAllSqIrrigationPlan',
  // 查询喷灌计划详情
  selectSqIrrigationPlanByPlanId: irriagatePlanUrl + 'selectSqIrrigationPlanByPlanId',
  // 分页查询喷灌计划列表
  selectSqIrrigationPlanByPage: irriagatePlanUrl + 'selectSqIrrigationPlanByPage',
  // 告警
  selectSqWarningByPage: sqWarningUrl + 'selectSqWarningByPage'
  // 实时监测
  // 喷灌任务
  // 喷灌计划
}
