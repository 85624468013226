
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Menu extends Vue {
  @Prop({ default: [] }) private menus?: any

  menuState = true

  get active () {
    const path = this.$route.path.split('/')
    const white = ['ecologyMonitor', 'secureRiskMonitor', 'maintainJobMonitor', 'patrol', 'laborManageList', 'property']
    const black = ['pestControl', 'recruitUse', 'laborManageList'] // 3级进入4级概览概览页面
    if ((this.$route.query.param && !black.includes(path[1])) || (white.includes(path[1]) && path.length === 3)) {
      // 概览菜单和3级菜单
      return this.$route.fullPath
      // 3级菜单内容页
    } else if (white.includes(path[1])) {
      return this.$route.fullPath.slice(0, this.$route.fullPath.lastIndexOf('/'))
    } else {
      // 其他菜单
      return '/' + path[1]
    }
  }

  // 解决menu变化失效的原理
  @Watch('menus', { immediate: true })
  menusChange () {
    this.menuState = false
    setTimeout(() => {
      this.menuState = true
    })
  }

  created () {
    // console.log('点击上方列表', this.menus)
    // 点击顶部一级菜单
    // this.menus.forEach((item: any, index: any) => {
    //   if (item.url === '/workBench') {
    //     // 如果点击工作台且该用户有工作台概览的权限，我们把它存储在本地（以供点击左侧工作台概览手动跳转路由）
    //     window.sessionStorage.setItem('workBeach', JSON.stringify(item.childNode))
    //     // 我们把工作台概览的子路由删掉（需求左侧菜单不显示）
    //     delete item.childNode
    //   }
    // })

    // if (this.$route.path.split('/')[1] === 'desk' || this.$route.path.split('/')[1] === 'system' || this.$route.path.split('/')[1] === 'manage') {
    //   const path = this.menus[0].childNode ? this.menus[0].childNode[0].url : this.menus[0].url
    //   // if (this.menus[0]?.url === '/workBench') {
    //   //   // console.log('工作台在最上方', JSON.parse(window.sessionStorage.getItem('workBeach') as any))
    //   //   this.$router.push({
    //   //     path: JSON.parse(window.sessionStorage.getItem('workBeach') as any)[0]?.url,
    //   //     query: this.$route.query
    //   //   })
    //   // } else {
    //   // console.log('没有点击工作台')
    //   this.$router.push({
    //     path: path,
    //     query: this.$route.query
    //   })
    //   // }
    // }
  }

  // clickMenu (data1: any) {
  //   // 点击左侧工作台概览手动跳转路由
  //   if (data1.url === '/workBench') {
  //     this.$router.push({
  //       path: JSON.parse(window.sessionStorage.getItem('workBeach') as any)[0]?.url,
  //       query: this.$route.query
  //     })
  //   }
  // }
}
