
import { Component, Vue } from 'vue-property-decorator'
import TopMenu from './menu/Top.vue'

@Component({
  components: {
    TopMenu
  }
})
export default class Layout extends Vue {
  private userInfo = ''
  private imgLoading = false
  // private websocket: any = ''
  private notify: any = null
  private messageInfo: any = {}
  private defaultImg = require('@/assets/img/avatarDefault.png')
  isfullscreen = false
  get avatar () {
    const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') as any)
    const storeUserInfo = this.$store.state.userInfo && this.$store.state.userInfo.resource
    return userInfo && userInfo.resource ? userInfo.resource.filePrefix + userInfo.resource.fileUrl : storeUserInfo || this.defaultImg
  }

  get userName () {
    let name = ''
    if (this.$store.state.userInfo && this.$store.state.userInfo.userName) {
      name = this.$store.state.userInfo.userName
    } else if (window.sessionStorage.getItem('userInfo')) {
      name = JSON.parse(window.sessionStorage.getItem('userInfo') as any).userName
    }
    return name
  }

  get messageNum () {
    return this.$store.state.messageNum
  }

  created () {
    this.$store.dispatch('getProjectList')
    this.$store.dispatch('getNormalProjectList')
    this.$event.$on('fullscreenStyleChange', (val: boolean) => {
      this.isfullscreen = val
    })
  }

  // 下拉点击事件
  handleCommand (v: string) {
    if (v === '1') {
      this.$router.push({
        name: 'userInfo'
      })
    } else if (v === '2') {
      this.loginOut()
    }
  }

  loginOut () {
    this.$axios.post(this.$apis.user.logout).then(() => {
      window.sessionStorage.removeItem('token')
      window.sessionStorage.removeItem('userInfo')
      window.sessionStorage.removeItem('menuIndex')
      window.sessionStorage.removeItem('menuList')
      this.$store.state.userInfo = {}
      this.$router.push('/login')
      this.$message.success('退出成功')
    })
  }
}
