import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/orderDeploy',
    name: 'orderDeploy',
    component: () => import('@/views/workOrder/OrderDeploy.vue'),
    meta: {
      title: '工单配置'
    }
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    component: () => import('@/views/workOrder/MyOrder.vue'),
    meta: {
      title: '我的工单'
    }
  },
  {
    path: '/orderManage',
    name: 'orderManage',
    component: () => import('@/views/workOrder/OrderManage.vue'),
    meta: {
      title: '工单管理'
    }
  },
  {
    path: '/myOrder/add',
    name: 'myOrderAdd',
    component: () => import('@/views/workOrder/MyOrderAdd.vue'),
    meta: {
      title: '新增工单'
    }
  }

]

export default router
