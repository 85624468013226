// 物料地址
const url = '/business/yhMaterial/'
// 物料类型地址
const urls = '/business/yhMaterialType/'
// 供应商
const urlx = '/business/yhSupplier/'
// 机械
const urlc = '/business/yhMachinery/'
// 仓库
const urlh = '/business/yhWarehouse/'
// 入库
const urlr = '/business/yhStorage/'
// 出库
const urlo = '/business/yhCheckout/'
export interface Apis {
  /** 物料管理 */
  // 新增
  insertYhMaterial: string;
  // 修改
  updateYhMaterial: string;
  // 删除
  deleteYhMaterial: string;
  // 查询物料详情
  selectYhMaterialByMaterialId: string;
  // 分页查询物料列表
  selectYhMaterialByPage: string;
  // 导入物料
  importMaterial: string;
  // 启用禁用物料
  updateYhMaterialState: string;
  /** 物料类型 */
  // 新增
  insertYhMaterialType: string;
  // 修改
  updateYhMaterialType: string;
  // 删除
  deleteDataYhMaterialType: string;
  // 查询
  selectYhMaterialTypeByList: string;
  // 物料类型分页
  selectYhMaterialTypeByPage: string;
  // 启用/禁用物料类型
  updateYhMaterialTypeState: string;

  /** 供应商管理 */
  // 新增
  insertYhSupplier: string;
  // 修改
  updateYhSupplier: string;
  // 删除
  deleteYhSupplier: string;
  // 查询详情
  selectYhSupplierBySupplierId: string;
  // 分页查询列表
  selectYhSupplierByPage: string;

  // 查询出入库流水
  selectYhDetailedByPage: string;

  /** 机械管理 */
  // 新增
  insertYhMachinery: string;
  // 修改
  updateYhMachinery: string;
  // 删除
  deleteYhMachinery: string;
  // 查询详情
  selectYhMachineryByMachineryId: string;
  // 分页查询列表
  selectYhMachineryByPage: string;

  /** 仓库 */
  // 新增
  insertYhWarehouse: string;
  // 修改
  updateYhWarehouse: string;
  // 删除
  deleteYhWarehouse: string;
  // 查询
  selectYhWarehouseByList: string;

  /** 入库 */
  // 新增
  insertYhStorage: string;
  // 详情
  selectYhStorageById: string;
  // 导出
  exportExcel: string;
  // 查询
  selectYhStorageByPage: string;

  /** 出库 */
  // 新增
  insertYhCheckout: string;
  // 详情
  selectYhCheckoutById: string;
  // 导出
  exportExcel2: string;
  // 查询
  selectYhCheckoutByPage: string;
}

export const apis: Apis = {
  /** 物料管理 */
  insertYhMaterial: url + 'insertYhMaterial',
  updateYhMaterial: url + 'updateYhMaterial',
  deleteYhMaterial: url + 'deleteYhMaterial',
  updateYhMaterialState: url + 'updateYhMaterialState',
  selectYhMaterialByMaterialId: url + 'selectYhMaterialByMaterialId',
  selectYhMaterialByPage: url + 'selectYhMaterialByPage',
  importMaterial: url + 'importMaterial',
  /** 物料类型 */
  insertYhMaterialType: urls + 'insertYhMaterialType',
  updateYhMaterialType: urls + 'updateYhMaterialType',
  deleteDataYhMaterialType: urls + 'deleteDataYhMaterialType',
  selectYhMaterialTypeByList: urls + 'selectYhMaterialTypeByList',
  selectYhMaterialTypeByPage: urls + 'selectYhMaterialTypeByPage',
  updateYhMaterialTypeState: urls + 'updateYhMaterialTypeState',

  /** 供应商管理 */
  insertYhSupplier: urlx + 'insertYhSupplier',
  updateYhSupplier: urlx + 'updateYhSupplier',
  deleteYhSupplier: urlx + 'deleteYhSupplier',
  selectYhSupplierBySupplierId: urlx + 'selectYhSupplierBySupplierId',
  selectYhSupplierByPage: urlx + 'selectYhSupplierByPage',
  /** 机械管理 */
  insertYhMachinery: urlc + 'insertYhMachinery',
  updateYhMachinery: urlc + 'updateYhMachinery',
  deleteYhMachinery: urlc + 'deleteYhMachinery',
  selectYhMachineryByMachineryId: urlc + 'selectYhMachineryByMachineryId',
  selectYhMachineryByPage: urlc + 'selectYhMachineryByPage',

  selectYhDetailedByPage: '/business/yhDetailed/selectYhDetailedByPage',

  /** 仓库管理 */
  insertYhWarehouse: urlh + 'insertYhWarehouse',
  updateYhWarehouse: urlh + 'updateYhWarehouse',
  deleteYhWarehouse: urlh + 'deleteYhWarehouse',
  selectYhWarehouseByList: urlh + 'selectYhWarehouseByList',

  /** 入库管理 */
  insertYhStorage: urlr + 'insertYhStorage',
  selectYhStorageById: urlr + 'selectYhStorageById',
  selectYhStorageByPage: urlr + 'selectYhStorageByPage',
  exportExcel: urlr + 'exportExcel',

  /** 入库管理 */
  insertYhCheckout: urlo + 'insertYhCheckout',
  selectYhCheckoutById: urlo + 'selectYhCheckoutById',
  selectYhCheckoutByPage: urlo + 'selectYhCheckoutByPage',
  exportExcel2: urlo + 'exportExcel'
}
