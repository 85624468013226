import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  // {
  //   path: '/property',
  //   name: 'property',
  //   meta: {
  //     title: '物料管理'
  //   },
  //   // redirect: '/property/materialmanage',
  //   component: () => import('@/components/menuPage/Index.vue'),
  //   children: [
  //     {
  //       path: 'materialmanage',
  //       props: true,
  //       meta: { title: '物料管理', hasBack: false },
  //       component: () => import('@/views/material/manage/Manage.vue')
  //     },
  //     {
  //       path: 'materialwarehousing',
  //       meta: { title: '入库管理', hasBack: false },
  //       component: () => import('@/views/material/warehousing/Warehousing.vue')
  //     },
  //     {
  //       path: 'materialexwarehouse',
  //       meta: { title: '出库管理', hasBack: false },
  //       component: () => import('@/views/material/exwarehouse/Exwarehouse.vue')
  //     },
  //     {
  //       path: 'flowingwater',
  //       meta: { title: '出入库流水', hasBack: false },
  //       component: () => import('@/views/material/flowingwater/Index.vue')
  //     },
  //     {
  //       path: 'inventoryquery',
  //       meta: { title: '库存查询', hasBack: false },
  //       component: () => import('@/views/material/inventoryquery/Index.vue')
  //     }
  //   ]
  // },
  {
    path: '/property/materialmanage',
    props: true,
    meta: { title: '物料管理', hasBack: false },
    component: () => import('@/views/material/manage/Manage.vue')
  },
  {
    path: '/property/materialwarehousing',
    meta: { title: '入库管理', hasBack: false },
    component: () => import('@/views/material/warehousing/Warehousing.vue')
  },
  {
    path: '/property/materialexwarehouse',
    meta: { title: '出库管理', hasBack: false },
    component: () => import('@/views/material/exwarehouse/Exwarehouse.vue')
  },
  {
    path: '/property/flowingwater',
    meta: { title: '出入库流水', hasBack: false },
    component: () => import('@/views/material/flowingwater/Index.vue')
  },
  {
    path: '/property/inventoryquery',
    meta: { title: '库存查询', hasBack: false },
    component: () => import('@/views/material/inventoryquery/Index.vue')
  },
  {
    path: '/property/materialmanage/add',
    meta: { title: '新增物料分类' },
    component: () => import('@/views/material/manage/ManageAdd.vue')
  },
  {
    path: '/property/materialmanage/edit',
    meta: { title: '编辑物料分类' },
    component: () => import('@/views/material/manage/ManageAdd.vue')
  },
  {
    path: '/property/materialmanage/detail',
    meta: { title: '物料分类详情' },
    component: () => import('@/views/material/manage/ManageDetail.vue')
  },
  {
    path: '/property/materialwarehousing/add',
    meta: { title: '添加入库单' },
    component: () => import('@/views/material/warehousing/WarehousingAdd.vue')
  },
  {
    path: '/property/materialwarehousing/detail',
    meta: { title: '入库详情' },
    component: () => import('@/views/material/warehousing/WarehousingDetail.vue')
  },
  {
    path: '/property/materialexwarehouse/add',
    meta: { title: '添加出库单' },
    component: () => import('@/views/material/exwarehouse/ExwarehouseAdd.vue')
  },
  {
    path: '/property/materialexwarehouse/detail',
    meta: { title: '出库详情' },
    component: () => import('@/views/material/exwarehouse/ExwarehouseDetail.vue')
  }
]

export default router
