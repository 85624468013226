// 用工管理
// 排班管理
const url = '/business/yhWorkerRecord/'
// 工人相关信息
const urls = '/business/yhWorkerInfo/'
// 排版规则
const urlx = '/business/yhWorkerRules/'
export interface Apis {
  // 排班管理
  // 新增
  insertYhWorkerRecord: string;
  // 修改
  updateYhWorkerRecord: string;
  // 删除
  deleteDataYhWorkerRecord: string;
  // 详情
  selectYhWorkerRecordByRecordId: string;
  // 列表
  selectYhWorkerRecordByPage: string;

  // 工人信息
  // 绑定设备
  insertYhWorkerInfo: string;
  // 查询设备下拉列表
  selectYhDeviceByList: string;
  // 查询工人下拉列表
  selectYhWorkerByList: string;
  // 分页查询工人列表
  selectYhWorkerInfoByPage: string;
  // 分页查询项目工人聚合列表
  selectYhWorkerProjectByPage: string;
  // 查询工人状态统计
  selectYhStatusCount: string;
  // 解除SOS状态
  updateYhWorkerSos: string;
  // 解除绑定设备
  deleteYhWorkerInfo: string;
  // 查询工人轨迹
  selectYhTrajectory: string;
  // 查询工人在岗统计
  selectYhDutyCount: string;
  // 查询工人在岗详情
  selectYhWorkerDutyCount: string;
  // 导出工人在岗统计
  exportWorkerDutyCount: string;

  // 排班规则
  // 修改排班规则
  updateYhWorkerRules: string;
  // 查询排班规则详情
  selectYhWorkerRulesByProjectId: string;
  // 分页查询排班规则列表
  selectYhWorkerRulesByPage: string;
}

export const apis: Apis = {
  insertYhWorkerRecord: url + 'insertYhWorkerRecord',
  updateYhWorkerRecord: url + 'updateYhWorkerRecord',
  deleteDataYhWorkerRecord: url + 'deleteDataYhWorkerRecord',
  selectYhWorkerRecordByRecordId: url + 'selectYhWorkerRecordByRecordId',
  selectYhWorkerRecordByPage: url + 'selectYhWorkerRecordByPage',
  insertYhWorkerInfo: urls + 'insertYhWorkerInfo',
  selectYhDeviceByList: urls + 'selectYhDeviceByList',
  selectYhWorkerByList: urls + 'selectYhWorkerByList',
  selectYhWorkerInfoByPage: urls + 'selectYhWorkerInfoByPage',
  selectYhWorkerProjectByPage: urls + 'selectYhWorkerProjectByPage',
  selectYhStatusCount: urls + 'selectYhStatusCount',
  updateYhWorkerSos: urls + 'updateYhWorkerSos',
  deleteYhWorkerInfo: urls + 'deleteYhWorkerInfo',
  selectYhTrajectory: urls + 'selectYhTrajectory',
  selectYhDutyCount: urls + 'selectYhDutyCount',
  selectYhWorkerDutyCount: urls + 'selectYhWorkerDutyCount',
  exportWorkerDutyCount: urls + 'exportWorkerDutyCount',
  updateYhWorkerRules: urlx + 'updateYhWorkerRules',
  selectYhWorkerRulesByProjectId: urlx + 'selectYhWorkerRulesByProjectId',
  selectYhWorkerRulesByPage: urlx + 'selectYhWorkerRulesByPage'
}
