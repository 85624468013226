// 接口名定义
export interface Apis {
  // 要素
  // 新增要素
  insertElement: string;
  // 修改要素
  updateElement: string;
  // 删除要素
  deleteElement: string;
  // 查询巡查要素详情
  selectElementByElementId: string;
  // 查询巡查要素列表
  selectElementByList: string;
  // 分页查询巡查要素列表
  selectElementByPage: string;
  // 查询巡查要素总览列表
  selectElementViewList: string;
  // 巡查人员设置
  // 新增巡查人员设置(品质监督)
  insertPZAQUserConfig: string;
  // 新增巡查人员设置(安全管理)
  insertAQGLUserConfig: string;
  // 新增巡查人员设置(其他条口)
  addOtherUserConfig: string;
  // 修改巡查人员设置(安全管理)
  updateAQGLUserConfig: string;
  // 修改巡查人员设置(其他条口)
  updateOtherUserConfig: string;
  // 删除巡查人员设置(品质监督)
  deletePZAQDataUserConfig: string;
  // 删除巡查人员设置(安全管理)
  deleteAQGLDataUserConfig: string;
  // 查询巡查人员设置列表(品质监督)
  selectPZAQUserConfigByList: string;
  // 查询巡查人员设置列表(安全管理)
  selectAQGLUserConfigByList: string;
  // 查询巡查人员设置列表(其他)
  selectOtherUserConfigByList: string;
  // 删除巡查人员设置(其他条口)
  deleteOtherUserConfig: string;
  // 查询巡查办理人员
  selectUserList: string;
  // 查询安全管理类型列表
  selectSafeManageList: string;
  // **** 巡查点位 begin ********
  // 设施管理
  insertFacility: string;
  deleteFacility: string;
  updateFacility: string;
  selectFacilityByFacilityId: string;
  selectFacilityByPage: string;
  selectFacilityByList: string;
  // 查询所有设施类型和设施列表
  selectTypeFacilitysList: string;
  // 设施类型
  selectFacilityTypeByList: string;
  insertFacilityType: string;
  updateFacilityType: string;
  deleteFacilityType: string;
  selectFacilityTypeByFacilityTypeId: string;
  // icon
  insertIcon: string;
  selectIconByList: string;
  // **** 巡查点位 end ********

  // 计划
  // 新增巡查计划
  insertPlane: string;
  // 禁用/启用巡查计划
  updatePlanStatus: string;
  // 编辑巡查计划
  updatePlane: string;
  // 删除巡查计划
  deletePlane: string;
  // 查询巡查计划详情
  selectPlaneByPlaneId: string;
  // 查询巡查计划列表
  selectPlaneByList: string;
  // 分页查询管理巡查巡查计划列表
  selectPlaneByPage: string;
  // 项目自查
  selectPlanePage: string;
  // 转办任务
  transferTask: string;
  // 删除巡查任务
  deleteTask: string;
  // 公园巡查路线点位信息
  selectLinePoint: string;
  // 任务
  // 查询巡查任务当月统计
  selectCurrentMonthStatistic: string;
  // 查询任务详情
  selectTaskByTaskId: string;
  // 查询任务详情 线路轨迹
  selectTrackList: string;
  // 查询线路巡查执行中 最新一个点位
  selectNewTrack: string;
  // 分页查询巡查任务
  selectTaskByPage: string;
  // 取消巡查任务
  taskCancel: string;
  // 问题
  // 分页查询巡查问题列表
  selectIssueByPage: string;
  // 查询巡查问题列表
  selectIssueByList: string;
  // 问题详情
  selectIssueByIssueId: string;
  // 问题关闭
  closeIssue: string;
  // 问题转办
  transferIssue: string;
  // 问题审核
  approvalIssue: string;
  // 问题反馈
  feedbackIssue: string;
  // 问题上报
  insertIssue: string;
  // 导出问题清单
  exportIssue: string;
  // 计划导出excel
  planexporttoexcel: string;
  // 任务导出excel
  taskexporttoexcel: string;
  // 查询导出文件列表
  selectExportByList: string;
  // 删除导出文件记录
  deleteExport: string;
  // 资产导出
  facilityExcel: string;
  urgeIssue: string;
}

export const apis: Apis = {
  // 要素
  // 新增要素
  insertElement: '/business/element/insertElement',
  // 修改要素
  updateElement: '/business/element/updateElement',
  // 删除要素
  deleteElement: '/business/element/deleteElement',
  // 查询巡查要素详情
  selectElementByElementId: '/business/element/selectElementByElementId',
  // 查询巡查要素列表
  selectElementByList: '/business/element/selectElementByList',
  // 分页查询巡查要素列表
  selectElementByPage: '/business/element/selectElementByPage',
  // 查询巡查要素总览列表
  selectElementViewList: '/business/element/selectElementViewList',
  // 巡查人员设置
  // 新增巡查人员设置(品质监督)
  insertPZAQUserConfig: '/business/userConfig/insertPZAQUserConfig',
  // 新增巡查人员设置(安全管理)
  insertAQGLUserConfig: '/business/userConfig/insertAQGLUserConfig',
  // 新增巡查人员设置(其他条口)
  addOtherUserConfig: '/business/userConfig/addOtherUserConfig',
  // 修改巡查人员设置(安全管理)
  updateAQGLUserConfig: '/business/userConfig/updateAQGLUserConfig',
  // 修改巡查人员设置(其他条口)
  updateOtherUserConfig: '/business/userConfig/updateOtherUserConfig',
  // 删除巡查人员设置(品质监督)
  deletePZAQDataUserConfig: '/business/userConfig/deletePZAQDataUserConfig',
  // 删除巡查人员设置(安全管理)
  deleteAQGLDataUserConfig: '/business/userConfig/deleteAQGLDataUserConfig',
  // 查询巡查人员设置列表(品质监督)
  selectPZAQUserConfigByList: '/business/userConfig/selectPZAQUserConfigByList',
  // 查询巡查人员设置列表(安全管理)
  selectAQGLUserConfigByList: '/business/userConfig/selectAQGLUserConfigByList',
  // 查询巡查人员设置列表(其他)
  selectOtherUserConfigByList: '/business/userConfig/selectOtherUserConfigByList',
  // 删除巡查人员设置(其他条口)
  deleteOtherUserConfig: '/business/userConfig/deleteOtherUserConfig',
  // 查询巡查办理人员
  selectUserList: '/business/userConfig/selectUserList',
  // 查询安全管理类型列表
  selectSafeManageList: '/business/userConfig/selectSafeManageList',
  // **** 巡查点位 begin ********
  // 设施管理
  insertFacility: '/business/facility/insertFacility',
  deleteFacility: '/business/facility/deleteFacility',
  updateFacility: '/business/facility/updateFacility',
  selectFacilityByFacilityId: '/business/facility/selectFacilityByFacilityId',
  selectFacilityByPage: '/business/facility/selectFacilityByPage',
  // 查询所有设施类型和设施列表
  selectTypeFacilitysList: '/business/facility/selectTypeFacilitysList',
  // 设施类型
  selectFacilityTypeByList: '/business/facilityType/selectFacilityTypeByList',
  insertFacilityType: '/business/facilityType/insertFacilityType',
  updateFacilityType: '/business/facilityType/updateFacilityType',
  deleteFacilityType: '/business/facilityType/deleteFacilityType',
  selectFacilityTypeByFacilityTypeId: '/business/facilityType/selectFacilityTypeByFacilityTypeId',
  // icon
  insertIcon: '/business/icon/insertIcon',
  selectIconByList: '/business/icon/selectIconByList',
  // **** 巡查点位 end ********
  // 计划
  // 新增巡查计划
  insertPlane: '/business/plane/insertPlane',
  // 禁用/启用巡查计划
  updatePlanStatus: '/business/plane/updatePlanStatus',
  // 编辑巡查计划
  updatePlane: '/business/plane/updatePlane',
  // 删除巡查计划
  deletePlane: '/business/plane/deletePlane',
  // 查询巡查计划详情
  selectPlaneByPlaneId: '/business/plane/selectPlaneByPlaneId',
  // 查询巡查计划列表
  selectPlaneByList: '/business/plane/selectPlaneByList',
  // 分页查询巡查计划列表
  selectPlaneByPage: '/business/plane/selectPlaneByPage',
  // 分页查询巡查计划列表
  selectPlanePage: '/business/plane/selectPlanePage',
  // 转办任务
  transferTask: '/business/task/transferTask',
  // 删除巡查任务
  deleteTask: '/business/task/deleteTask',
  // 公园巡查路线点位信息
  selectLinePoint: '/business/patrolStatistic/selectLinePoint',
  // 任务
  // 查询巡查任务当月统计
  selectCurrentMonthStatistic: '/business/task/selectCurrentMonthStatistic',
  // 查询任务详情
  selectTaskByTaskId: '/business/task/selectTaskByTaskId',
  // 查询任务详情 线路轨迹
  selectTrackList: '/business/task/selectTrackList',
  // 查询线路巡查执行中 最新一个点位
  selectNewTrack: '/business/task/selectNewTrack',
  // 分页查询巡查任务
  selectTaskByPage: '/business/task/selectTaskByPage',
  // 取消巡查任务
  taskCancel: '/business/task/cancel',
  // 问题
  // 分页查询巡查问题列表
  selectIssueByPage: '/business/hxIssue/selectIssueByPage',
  // 查询巡查问题列表
  selectIssueByList: '/business/hxIssue/selectIssueByList',
  // 问题详情
  selectIssueByIssueId: '/business/hxIssue/selectIssueByIssueId',
  // 问题关闭
  closeIssue: '/business/hxIssue/closeIssue',
  // 问题转办
  transferIssue: '/business/hxIssue/transferIssue',
  // 问题审核
  approvalIssue: '/business/hxIssue/approvalIssue',
  // 问题反馈
  feedbackIssue: '/business/hxIssue/feedbackIssue',
  // 问题上报
  insertIssue: '/business/hxIssue/insertIssue',

  // 导出问题清单
  exportIssue: '/business/hxIssue/exportIssue',
  // 计划导出excel
  planexporttoexcel: '/business/plane/excel',
  // 任务导出excel
  taskexporttoexcel: '/business/task/excel',
  // 查询导出文件列表
  selectExportByList: '/business/export/selectExportByList',
  // 删除导出文件记录
  deleteExport: '/business/export/deleteExport',
  // 资产导出
  facilityExcel: '/business/facility/excel',
  selectFacilityByList: '/business/facility/selectFacilityByList',
  urgeIssue: '/business/hxIssue/urgeIssue'
}
