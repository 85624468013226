import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/seedlingInspection',
    name: 'seedlingInspection',
    meta: { title: '苗木盘点' },
    component: () => import('@/views/seedlingInspection/Index.vue')
  },
  // 新增
  {
    path: '/seedlingInspection/add',
    name: 'seedlingInspectionAdd',
    component: () => import('@/views/seedlingInspection/Add.vue'),
    meta: {
      title: '新增'
    }
  },
  // 详情
  {
    path: '/seedlingInspection/detail',
    name: 'seedlingInspectionDetail',
    component: () => import('@/views/seedlingInspection/Details.vue'),
    meta: {
      title: '详情'
    }
  },
  {
    path: '/seedlingInspection/seedlingInspection',
    name: 'seedlingInspectionInventory',
    component: () => import('@/views/seedlingInspection/Details.vue'),
    meta: {
      title: '盘点'
    }
  }
]

export default router
