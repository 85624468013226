import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/maintenanceDaily',
    name: 'maintenanceDaily',
    component: () => import('@/views/maintenanceDay/Index.vue'),
    meta: {
      title: '养护日报'
    }
  },
  {
    path: '/maintenanceDaily/add',
    name: 'maintenanceDailyAdd',
    meta: {
      title: '新增养护日报'
    },
    component: () => import('@/views/maintenanceDay/AddMaintenanceDay.vue')
  },
  {
    path: '/maintenanceDaily/edit',
    name: 'maintenanceDailyEdit',
    meta: {
      title: '编辑养护日报'
    },
    component: () => import('@/views/maintenanceDay/AddMaintenanceDay.vue')
  },
  {
    path: '/maintenanceDaily/details',
    name: 'maintenanceDayDetails',
    meta: {
      title: '日报详情',
      hasBack: false
    },
    // component: () => import('@/views/maintenanceDay/maintenanceDayDetails.vue')
    component: () => import('@/views/maintenanceDay/maintenanceDayDetails2.vue')
  },
  {
    path: '/maintenanceDaily/DailyTemplate',
    name: 'DailyTemplate',
    component: () => import('@/views/maintenanceDay/DailyTemplateList.vue'),
    meta: {
      title: '日报模板'
    }
  }
  // ,
  // {
  //   path: '/planReport/WeekPlanAdd',
  //   name: 'WeekPlanAdd',
  //   component: () => import('@/views/operationmanage/planReportAdd/WeekPlanAdd.vue'),
  //   meta: {
  //     title: '周工作计划新增'
  //   }
  // }
]

export default router
