export interface Apis {
  // ***获取角色列表
  getRoleList: string;
  // ***获取角色用户列表
  getRoleUserList: string;
  // ***获取省市区{label: '江苏省', children:[{label: '南京市', children:[{label: '江宁区'}...]}...]}
  getRegion: string;
  upload: string;
  // 七牛上传地址
  uploadQiniup: string;
  download: string;
  // 获取当前登录角色菜单列表
  getMenu: string;
  // ***数据字典查询
  selectDicByList: string;
  // 获取导入文件模板地址
  selectTemplate: string;
  // ***七牛文件上传凭据
  uploadToken: string;
  // 应用广场一键开通
  insertCustomerAbilityCodes: string;
}

export const apis: Apis = {
  getRoleList: '/business/yhRole/selectYhRoleByList',
  getRoleUserList: '/business/yhUser/selectYhUserByList',
  getRegion: '/business/yhDic/selectCity',
  upload: '/user/file/upload',
  uploadQiniup: 'https://upload.qiniup.com',
  download: '/user/file/download',
  getMenu: '/business/yhMenu/selectYhMenuByList',
  selectDicByList: '/business/yhDic/selectDicByList',
  selectTemplate: '/business/selectTemplate',
  uploadToken: '/business/qiniu/getToken',
  insertCustomerAbilityCodes: '/admin/customer/insertCustomerAbilityCodes'
}
