// 机械库
const machinerylUrl = '/business/yhMachineryInfo/'
// 机械管理
// 保养项
const mowlUrl = '/business/yhMachineryTerm/'
const strategyUrl = '/business/yhMachineryStrategy/'
const callUrl = '/business/yhMachineryTransfer/' // 机械调用
const infoUrl = '/business/yhMachineryInfo/' // 机械信息
const returnUrl = '/business/yhMachineryRecord/' // 机械领还
const repairUrl = '/business/yhMachineryRepair/' // 机械保修
const HeadUrl = '/business/yhMachineryUser/' // 报修/保养负责人
const MaintenanceUrl = '/business/yhMachineryMaintenance/' // 机械保修

export interface Apis {
  // 机械库
  // 新增机械信息
  insertYhMachineryInfo: string;
  // 修改机械信息
  updateYhMachineryInfo: string;
  // 终止使用/还原使用
  updateYhMachineryInfoState: string;
  // 删除机械信息
  deleteYhMachineryInfo: string;
  // 查询机械信息详情
  selectYhMachineryInfoByMachineryId: string;
  // 分页查询机械信息列表
  selectYhMachineryInfoByPage: string;
  // 下载机械二维码
  downloadQR: string;
  // 查询机械信息保养项详情
  selectStrategyByMachineryIdApp: string;

  // 新增机械保养项
  insertYhMachineryTerm: string;
  // 修改机械保养项
  updateYhMachineryTerm: string;
  // 物理删除机械保养项
  deleteDataYhMachineryTerm: string;
  // 查询机械保养项列表
  selectYhMachineryTermByList: string;
  // 新增机械保养策略
  insertYhMachineryStrategy: string;
  // 修改机械保养策略
  updateYhMachineryStrategy: string;
  // 启用/禁用保养策略
  updateYhMachineryStrategyState: string;
  // 删除机械保养策略
  deleteYhMachineryStrategy: string;
  // 查询机械保养策略详情
  selectYhMachineryStrategyByStrategyId: string;
  // 分页查询机械保养策略列表
  selectYhMachineryStrategyByPage: string;

  // 领还
  selectYhMachineryRecordByPage: string;
  // 维修
  selectYhMachineryRepairByPage: string;
  // 保养
  selectYhMachineryMaintenanceByPage: string;
  // 分页查询机械调用列表
  selectYhMachineryCallByPage: string;
  // 按日期查询可以领用的机械清单
  selectYhMachineryInfoTree: string;
  // 新增机械调用单
  insertYhMachineryTransfer: string;
  // 删除机械调用单
  deleteYhMachineryTransfer: string;
  // 新增机械调用单
  updateYhMachineryTransfer: string;
  // 查询机械调用单详情
  selectYhMachineryTransferByTransferId: string;
  // 领用机械
  insertYhMachineryRecord: string;
  // 查询机械领还记录详情
  selectYhMachineryRecordByRecordId: string;
  // 归还机械
  updateYhMachineryRecord: string;
  // APP查询机械领用列表
  selectYhTransferUserByPage: string;

  // 新增机械报修单
  insertYhMachineryRepair: string;
  // 查询机械报修单详情
  selectYhMachineryRepairByMaintainId: string;
  // 开始维修
  startYhMachineryRepair: string;
  // 结束维修
  endYhMachineryRepair: string;
  // 确认报修
  confirmYhMachineryRepair: string;
  // 关闭报修
  closeYhMachineryRepair: string;
  // 转办报修
  forwardYhMachineryRepair: string;
  // 删除报修
  deleteYhMachineryRepair: string;

  // 查询报修负责人列表
  selectYhRepairUserByList: string;
  // 新增报修负责人
  insertYhRepairUser: string;
  // 修改报修负责人
  updateYhRepairUser: string;
  // 删除报修负责人
  deleteDataYhRepairUser: string;

  // 查询保养人列表
  selectYhMaintenanceUserByList: string;
  // 新增保养负责人
  insertYhMaintenanceUser: string;
  // 修改保养负责人
  updateYhMaintenanceUser: string;
  // 删除保养负责人
  deleteDataYhMaintenanceUser: string;

  // 新增机械保养单
  insertYhMachineryMaintenance: string;
  // 结束保养
  endYhMachineryMaintenance: string;
  // 确认机械保养单
  confirmYhMachineryMaintenance: string;
  // 结束维修和保养
  endRepairMaintenance: string;
  // 删除机械保养单
  deleteYhMachineryMaintenance: string;
  // 查询机械保养单详情
  selectYhMachineryMaintenanceByMaintainId: string;
}

export const apis: Apis = {
  insertYhMachineryInfo: machinerylUrl + 'insertYhMachineryInfo',
  updateYhMachineryInfo: machinerylUrl + 'updateYhMachineryInfo',
  updateYhMachineryInfoState: machinerylUrl + 'updateYhMachineryInfoState',
  deleteYhMachineryInfo: machinerylUrl + 'deleteYhMachineryInfo',
  selectYhMachineryInfoByMachineryId: machinerylUrl + 'selectYhMachineryInfoByMachineryId',
  selectYhMachineryInfoByPage: machinerylUrl + 'selectYhMachineryInfoByPage',
  downloadQR: machinerylUrl + 'downloadQR',
  selectStrategyByMachineryIdApp: machinerylUrl + 'selectStrategyByMachineryIdApp',
  insertYhMachineryTerm: mowlUrl + 'insertYhMachineryTerm',
  updateYhMachineryTerm: mowlUrl + 'updateYhMachineryTerm',
  deleteDataYhMachineryTerm: mowlUrl + 'deleteDataYhMachineryTerm',
  selectYhMachineryTermByList: mowlUrl + 'selectYhMachineryTermByList',
  insertYhMachineryStrategy: strategyUrl + 'insertYhMachineryStrategy',
  updateYhMachineryStrategy: strategyUrl + 'updateYhMachineryStrategy',
  updateYhMachineryStrategyState: strategyUrl + 'updateYhMachineryStrategyState',
  deleteYhMachineryStrategy: strategyUrl + 'deleteYhMachineryStrategy',
  selectYhMachineryStrategyByStrategyId: strategyUrl + 'selectYhMachineryStrategyByStrategyId',
  selectYhMachineryStrategyByPage: strategyUrl + 'selectYhMachineryStrategyByPage',
  selectYhMachineryRecordByPage: '/business/yhMachineryRecord/selectYhMachineryRecordByPage',
  selectYhMachineryRepairByPage: '/business/yhMachineryRepair/selectYhMachineryRepairByPage',
  selectYhMachineryMaintenanceByPage: '/business/yhMachineryMaintenance/selectYhMachineryMaintenanceByPage',
  selectYhMachineryCallByPage: callUrl + 'selectYhMachineryTransferByPage',
  selectYhMachineryInfoTree: infoUrl + 'selectYhMachineryInfoTree',
  insertYhMachineryTransfer: callUrl + 'insertYhMachineryTransfer',
  updateYhMachineryTransfer: callUrl + 'updateYhMachineryTransfer',
  deleteYhMachineryTransfer: callUrl + 'deleteYhMachineryTransfer',
  selectYhMachineryTransferByTransferId: callUrl + 'selectYhMachineryTransferByTransferId',
  insertYhMachineryRecord: returnUrl + 'insertYhMachineryRecord',
  selectYhMachineryRecordByRecordId: returnUrl + 'selectYhMachineryRecordByRecordId',
  updateYhMachineryRecord: returnUrl + 'updateYhMachineryRecord',
  selectYhRepairUserByList: HeadUrl + 'selectYhRepairUserByList',
  insertYhRepairUser: HeadUrl + 'insertYhRepairUser',
  updateYhRepairUser: HeadUrl + 'updateYhRepairUser',
  deleteDataYhRepairUser: HeadUrl + 'deleteDataYhRepairUser',
  selectYhTransferUserByPage: '/business/yhMachineryTransfer/selectYhTransferUserByPage',
  insertYhMachineryRepair: repairUrl + 'insertYhMachineryRepair',
  selectYhMachineryRepairByMaintainId: repairUrl + 'selectYhMachineryRepairByMaintainId',
  startYhMachineryRepair: repairUrl + 'startYhMachineryRepair',
  endYhMachineryRepair: repairUrl + 'endYhMachineryRepair',
  confirmYhMachineryRepair: repairUrl + 'confirmYhMachineryRepair',
  closeYhMachineryRepair: repairUrl + 'closeYhMachineryRepair',
  forwardYhMachineryRepair: repairUrl + 'forwardYhMachineryRepair',
  deleteYhMachineryRepair: repairUrl + 'deleteYhMachineryRepair',
  insertYhMachineryMaintenance: MaintenanceUrl + 'insertYhMachineryMaintenance',
  endYhMachineryMaintenance: MaintenanceUrl + 'endYhMachineryMaintenance',
  confirmYhMachineryMaintenance: MaintenanceUrl + 'confirmYhMachineryMaintenance',
  endRepairMaintenance: MaintenanceUrl + 'endRepairMaintenance',
  deleteYhMachineryMaintenance: MaintenanceUrl + 'deleteYhMachineryMaintenance',
  selectYhMachineryMaintenanceByMaintainId: MaintenanceUrl + 'selectYhMachineryMaintenanceByMaintainId',
  selectYhMaintenanceUserByList: HeadUrl + 'selectYhMaintenanceUserByList',
  insertYhMaintenanceUser: HeadUrl + 'insertYhMaintenanceUser',
  updateYhMaintenanceUser: HeadUrl + 'updateYhMaintenanceUser',
  deleteDataYhMaintenanceUser: HeadUrl + 'deleteDataYhMaintenanceUser'
}
