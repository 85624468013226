export interface Apis {
  // 喷灌设备
  // 喷灌电磁阀打点和修改名称
  updateIrrigationValveLocation: string;
  // 手动开启电磁阀
  insertIrrigationValveRecord: string;
  // 查询喷灌电磁阀列表
  selectIrrigationValveByList: string;
  // 分页查询喷灌电磁阀列表
  selectIrrigationValveByPage: string;
  // 查询喷灌土壤设备列表
  selectIrrigationSoilByList: string;
  // 查询喷灌水表设备列表
  selectIrrigationWaterByList: string;
  // 查询喷灌电表设备列表
  selectIrrigationElectricityByList: string;
  // 分页查询电磁阀执行记录列表
  selectIrrigationValveRecordByPage: string;
  // 喷灌区域
  // 新增喷灌区域
  insertIrrigationArea: string;
  // 修改喷灌区域
  updateIrrigationArea: string;
  // 删除喷灌区域
  deleteIrrigationArea: string;
  // 查询喷灌区域详情
  selectIrrigationAreaByAreaId: string;
  // 分页查询喷灌区域列表
  selectIrrigationAreaByPage: string;
  // 区域首页统计
  selectIrrigationAreaIndex: string;
  // 喷灌计划
  // 新增喷灌计划
  insertIrrigationPlan: string;
  // 修改喷灌计划
  updateIrrigationPlan: string;
  // 删除喷灌计划
  deleteIrrigationPlan: string;
  // 启用/禁用喷灌计划
  updateIrrigationPlanStatus: string;
  // 查询喷灌计划详情
  selectIrrigationPlanByPlanId: string;
  // 分页查询喷灌计划列表
  selectIrrigationPlanByPage: string;
  // 分页查询喷灌计划执行记录列表
  selectIrrigationPlanRecordByPage: string;
  // 获取各个项目电磁阀区域数
  selectIrrigationProjectWebList: string;
}
export const apis: Apis = {
  updateIrrigationValveLocation: '/business/irrigationDevice/updateIrrigationValveLocation',
  insertIrrigationValveRecord: '/business/irrigationDevice/insertIrrigationValveRecord',
  selectIrrigationValveByList: '/business/irrigationDevice/selectIrrigationValveByList',
  selectIrrigationValveByPage: '/business/irrigationDevice/selectIrrigationValveByPage',
  selectIrrigationWaterByList: '/business/irrigationDevice/selectIrrigationWaterByList',
  selectIrrigationSoilByList: '/business/irrigationDevice/selectIrrigationSoilByList',
  selectIrrigationElectricityByList: '/business/irrigationDevice/selectIrrigationElectricityByList',
  selectIrrigationValveRecordByPage: '/business/irrigationDevice/selectIrrigationValveRecordByPage',
  insertIrrigationArea: '/business/irrigationArea/insertIrrigationArea',
  updateIrrigationArea: '/business/irrigationArea/updateIrrigationArea',
  deleteIrrigationArea: '/business/irrigationArea/deleteIrrigationArea',
  selectIrrigationAreaByAreaId: '/business/irrigationArea/selectIrrigationAreaByAreaId',
  selectIrrigationAreaByPage: '/business/irrigationArea/selectIrrigationAreaByPage',
  selectIrrigationAreaIndex: '/business/irrigationArea/selectIrrigationAreaIndex',
  insertIrrigationPlan: '/business/irrigationPlan/insertIrrigationPlan',
  updateIrrigationPlan: '/business/irrigationPlan/updateIrrigationPlan',
  deleteIrrigationPlan: '/business/irrigationPlan/deleteIrrigationPlan',
  updateIrrigationPlanStatus: '/business/irrigationPlan/updateIrrigationPlanStatus',
  selectIrrigationPlanByPlanId: '/business/irrigationPlan/selectIrrigationPlanByPlanId',
  selectIrrigationPlanByPage: '/business/irrigationPlan/selectIrrigationPlanByPage',
  selectIrrigationPlanRecordByPage: '/business/irrigationPlan/selectIrrigationPlanRecordByPage',
  selectIrrigationProjectWebList: '/business/irrigationArea/selectIrrigationProjectWebList'
}
