import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/laborManageList/worker',
    name: 'worker',
    meta: {
      title: '工人信息',
      hasBack: false
    },
    component: () => import('@/views/projectWorker/List.vue')
  },
  {
    path: '/laborManageList/teams',
    name: 'teams',
    meta: {
      title: '班组信息',
      hasBack: false
    },
    component: () => import('@/views/laborManage/teams/Index.vue')
  },
  {
    path: '/laborManageList/attendance',
    name: 'attendance',
    meta: {
      title: '考勤记录',
      hasBack: false
    },
    component: () => import('@/views/laborManage/attendance/Index.vue')
  },
  {
    path: '/laborManageList/statistics',
    name: 'statistics',
    meta: {
      title: '考勤统计',
      hasBack: false
    },
    component: () => import('@/views/laborManage/attendance/Statistics.vue')
  },
  {
    path: '/laborManageList/taiBanLabor',
    name: 'taiBanLabor',
    meta: {
      title: '台班劳务',
      hasBack: false
    },
    component: () => import('@/views/laborManage/taiBanLabor/Index.vue')
  },
  {
    path: '/laborManageList/taiBanMachine',
    name: 'taiBanMachine',
    meta: {
      title: '台班机械',
      hasBack: false
    },
    component: () => import('@/views/laborManage/machine/MachineList.vue')
  },
  // {
  //   path: '/laborManageList',
  //   name: 'laborManageList',
  //   meta: {
  //     title: '劳务管理'
  //   },
  //   component: () => import('@/components/menuPage/Index.vue'),
  //   children: [
  //     {
  //       path: 'worker',
  //       name: 'worker',
  //       meta: {
  //         title: '工人信息',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/projectWorker/List.vue')
  //     },
  //     {
  //       path: 'teams',
  //       name: 'teams',
  //       meta: {
  //         title: '班组信息',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/laborManage/teams/Index.vue')
  //     },
  //     {
  //       path: 'attendance',
  //       name: 'attendance',
  //       meta: {
  //         title: '劳务考勤',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/laborManage/attendance/Index.vue')
  //     },
  //     {
  //       path: 'taiBanLabor',
  //       name: 'taiBanLabor',
  //       meta: {
  //         title: '台班劳务',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/laborManage/taiBanLabor/Index.vue')
  //     },
  //     {
  //       path: 'taiBanMachine',
  //       name: 'taiBanMachine',
  //       meta: {
  //         title: '台班机械',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/laborManage/machine/MachineList.vue')
  //     }
  //   ]
  // },
  {
    path: '/laborManageList/attendance/teamscorrect',
    name: 'correct',
    meta: {
      title: '修正记录',
      hasBack: true
    },
    component: () => import('@/views/laborManage/attendance/CorrectList.vue')
  },
  {
    path: '/laborManageList/taiBanMachine/add',
    meta: { title: '新增机械' },
    component: () => import('@/views/laborManage/machine/MachineAdd.vue')
  },
  {
    path: '/laborManageList/taiBanMachine/edit',
    meta: { title: '编辑机械' },
    component: () => import('@/views/laborManage/machine/MachineAdd.vue')
  },
  {
    path: '/laborManageList/taiBanMachine/detail',
    meta: { title: '机械详情' },
    component: () => import('@/views/laborManage/machine/MachineDetail.vue')
  },
  {
    path: '/laborManageList/taiBanMachine/add',
    meta: { title: '新增台班' },
    component: () => import('@/views/laborManage/taiBanLabor/Add.vue')
  },
  {
    path: '/laborManageList/taiBanMachine/edit',
    meta: { title: '编辑台班' },
    component: () => import('@/views/laborManage/taiBanLabor/Add.vue')
  },
  {
    path: '/laborManageList/taiBanMachine/detail',
    meta: { title: '台班详情' },
    component: () => import('@/views/laborManage/taiBanLabor/Details.vue')
  }
  // ,
  // {
  //   path: '/laborManageList/base/editBase:id',
  //   name: 'editBase',
  //   meta: {
  //     title: '编辑车辆'
  //   },
  //   component: () => import('@/views/laborManage/AddBase.vue')
  // },
  // {
  //   path: '/laborManageList/base/detailBase:id',
  //   name: 'detailBase',
  //   meta: {
  //     title: '车辆信息详情'
  //   },
  //   component: () => import('@/views/laborManage/DetailBase.vue')
  // },
  // {
  //   path: '/laborManageList/maintain/addMaintain',
  //   name: 'addMaintain',
  //   meta: {
  //     title: '新增维修信息'
  //   },
  //   component: () => import('@/views/laborManage/AddMaintain.vue')
  // },
  // {
  //   path: '/laborManageList/maintain/editMaintain:id',
  //   name: 'editMaintain',
  //   meta: {
  //     title: '编辑维修信息'
  //   },
  //   component: () => import('@/views/laborManage/AddMaintain.vue')
  // },
  // {
  //   path: '/laborManageList/maintain/detailMaintain:id',
  //   name: 'detailMaintain',
  //   meta: {
  //     title: '车辆维修详情'
  //   },
  //   component: () => import('@/views/laborManage/DetailMaintain.vue')
  // },
  // {
  //   path: '/laborManageList/illegal/addIllegal',
  //   name: 'addIllegal',
  //   meta: {
  //     title: '新增违章信息'
  //   },
  //   component: () => import('@/views/laborManage/AddIllegal.vue')
  // },
  // {
  //   path: '/laborManageList/illegal/editIllegal:id',
  //   name: 'editIllegal',
  //   meta: {
  //     title: '编辑违章信息'
  //   },
  //   component: () => import('@/views/laborManage/AddIllegal.vue')
  // },
  // {
  //   path: '/laborManageList/illegal/detailIllegal:id',
  //   name: 'detailIllegal',
  //   meta: {
  //     title: '车辆违章详情'
  //   },
  //   component: () => import('@/views/laborManage/DetailIllegal.vue')
  // }

]

export default router
