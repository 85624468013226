// 组织架构-人员列表
export interface Apis{
  personnelPage: string;
  personnelAdd: string;
  personnelUpdate: string;
  personnelDetail: string;
  personnelDisabled: string;
  personnelDelete: string;
  resetPassword: string;
  // 根据人员绑定项目
  insertYhProjectByUserId: string;
}
const url = '/business/yhUser/'
export const apis: Apis = {
  personnelPage: url + 'selectYhUserByPage',
  personnelAdd: url + 'insertYhUser',
  personnelUpdate: url + 'updateYhUser',
  personnelDetail: url + 'selectYhUserByUserId',
  personnelDisabled: url + 'deleteYhUser',
  personnelDelete: url + 'eliminateYhUser',
  resetPassword: url + 'resetPassword',
  insertYhProjectByUserId: '/business/yhProject/insertYhProjectByUserId'
}
