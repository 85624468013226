import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/projectWorkerList',
    name: 'projectWorkerList',
    component: () => import('@/views/projectWorker/List.vue'),
    meta: {
      title: '项目工人管理'
    }
  },
  {
    // path: '/projectWorkerList/add',
    path: '/laborManageList/worker/add',
    name: 'projectWorkerAdd',
    component: () => import('@/views/projectWorker/Add.vue'),
    meta: {
      title: '项目工人添加'
    }
  },
  {
    // path: '/projectWorkerList/edit:workerId',
    path: '/laborManageList/worker/edit:workerId',
    name: 'projectWorkerEdit',
    component: () => import('@/views/projectWorker/Add.vue'),
    meta: {
      title: '项目工人编辑'
    }
  }, {
    path: '/employment',
    name: 'employment',
    meta: { title: '考勤结果管理' },
    component: () => import('@/components/menuPage/Index.vue'),
    children: [{
      path: 'result',
      meta: {
        hasBack: false,
        title: '考勤结果管理'
      },
      component: () => import('@/views/projectWorker/ResultManage.vue')
    }, {
      path: 'report',
      meta: {
        hasBack: false,
        title: '考勤统计'
      },
      component: () => import('@/views/projectWorker/ReportManage.vue')
    }, {
      path: 'more',
      meta: {
        hasBack: false,
        title: '多人考勤'
      },
      component: () => import('@/views/projectWorker/MoreManage.vue')
    }]
  }
]

export default router
