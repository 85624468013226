// 养护日报
const Url = '/business/yhProjectDaily/' // 日报

const Url2 = '/business/yhDailyTemplate/' // 日报模板
export interface Apis {
  // 分页查询项目日报列表
  selectYhProjectDailyByPage: string;
  // 新增
  insertYhProjectDaily: string;
  // 编辑
  updateYhProjectDaily: string;
  // 删除
  deleteYhProjectDaily: string;
  // 查询项目日报详情
  selectYhProjectDailyShareDetail: string;
  // 日报校验
  checkYhProjectDaily: string;
  // 查询项目日报的明日计划列表
  selectYhProjectDailyTomorrowPlanList: string;
  // 查询项目日报-养护作业
  selectDailyOrder: string;
  // 查询项目日报-明日计划
  selectDailyTomorrowPlan: string;

  // 分页查询日报模板列表
  selectYhDailyTemplateByPage: string;
  // 新增日报模板
  insertYhDailyTemplate: string;
  // 修改日报模板
  updateYhDailyTemplate: string;
  // 禁用/启用日报模板
  disableYhDailyTemplate: string;
  // 删除日报模板
  deleteYhDailyTemplate: string;
  // 查询日报模板详情
  selectYhDailyTemplateByTemplateId: string;
  // 查询项目的模板列表
  selectYhProjectDailyTemplateList: string;
  // 查询项目的日报项配置列表
  selectProjectOptionList: string;
  // 劳务考勤
  selectLabourServiceStatisticByDaily: string;
  // 查询已创建项目日报的时间列表
  selectYhProjectDailyDateList: string;

  // 物料管理
  selectYhDetailedDaily: string;
  // 台班劳务
  selectYhMachineTeamLineByList: string;

}
export const apis: Apis = {
  selectYhProjectDailyByPage: Url + 'selectYhProjectDailyByPage',
  insertYhProjectDaily: Url + 'insertYhProjectDaily',
  updateYhProjectDaily: Url + 'updateYhProjectDaily',
  selectYhProjectDailyShareDetail: Url + 'selectYhProjectDailyShareDetail',
  checkYhProjectDaily: Url + 'checkYhProjectDaily',
  selectDailyTomorrowPlan: Url + 'selectDailyTomorrowPlan',
  selectDailyOrder: Url + 'selectDailyOrder',
  deleteYhProjectDaily: Url + 'deleteYhProjectDaily',
  selectYhProjectDailyTomorrowPlanList: Url + 'selectYhProjectDailyTomorrowPlanList',
  selectYhDailyTemplateByPage: Url2 + 'selectYhDailyTemplateByPage',
  insertYhDailyTemplate: Url2 + 'insertYhDailyTemplate',
  updateYhDailyTemplate: Url2 + 'updateYhDailyTemplate',
  disableYhDailyTemplate: Url2 + 'disableYhDailyTemplate',
  deleteYhDailyTemplate: Url2 + 'deleteYhDailyTemplate',
  selectYhDailyTemplateByTemplateId: Url2 + 'selectYhDailyTemplateByTemplateId',
  selectYhProjectDailyTemplateList: Url2 + 'selectYhProjectDailyTemplateList',
  selectProjectOptionList: Url2 + 'selectProjectOptionList',
  selectLabourServiceStatisticByDaily: '/business/yhAttendanceStatistics/selectLabourServiceStatisticByDaily',
  selectYhProjectDailyDateList: Url + 'selectYhProjectDailyDateList',
  selectYhDetailedDaily: '/business/yhDetailed/selectYhDetailedDaily',
  selectYhMachineTeamLineByList: '/business/yhMachineTeam/selectYhMachineTeamLineByList'
}
