// 土壤监测
const soilUrl = '/business/soilInfo/'
const insectUrl = '/business/insectInfo/'
const waterUrl = '/business/waterQualityInfo/'
const weatherUrl = '/business/weatherInfo/'
export interface Apis {
  // 设备分配记录
  selectDeviceAllotByPage: string;

  /* 土壤监测 */
  // 分页查询土壤监测列表
  selectSoilInfoByPage: string;
  // 批量分配土壤监测
  updateSoilInfo: string;
  // 查询土壤监测项目列表
  selectProjectSoilByList: string;
  // 查询土壤监测详情
  selectSoilInfoByDeviceNumber: string;
  // 修改土壤监测配置
  updateSoilInfoConf: string;
  // 修改打点及设备名称
  updateSoilInfoLocation: string;
  // 历史数据列表
  selectSoilDataByPage: string;
  // 分页查询土壤预警
  selectSoilWarningByPage: string;

  /* 智慧虫情 */
  // 分页查询智慧虫情列表
  selectInsectInfoByPage: string;
  // 批量分配智慧虫情
  updateInsectInfo: string;
  // 查询智慧虫情详情
  selectInsectInfoByDeviceNumber: string;
  // 修改打点及设备名称
  updateInsectInfoLocation: string;
  // 查询智慧虫情项目列表
  selectProjectInsectByList: string;
  // 虫情图片历史数据
  selectInsectImgByPage: string;
  // 虫情信息历史数据
  selectInsectDataByPage: string;

  /* 水质监测 */
  // 分页查询水质列表
  selectWaterQualityInfoByPage: string;
  // 批量分配水质
  updateWaterQualityInfo: string;
  // 查询水质监测详情
  selectWaterQualityInfoByDeviceNumber: string;
  // 修改打点及设备名称
  updateWaterQualityInfoLocation: string;
  // 查询水质监测项目列表
  selectProjectWaterQualityByList: string;
  // 分页查询智慧水质监测历史数据
  selectWaterQualityDataByPage: string;

  /* 环境监测 */
  // 分页查询环境列表
  selectWeatherInfoByPage: string;
  // 批量分配环境
  updateWeatherInfo: string;
  // 查询环境监测详情
  selectWeatherInfoByDeviceNumber: string;
  // 修改打点及设备名称
  updateWeatherInfoLocation: string;
  // 查询环境监测项目列表
  selectProjectWeatherByList: string;
  // 分页查询智慧环境监测历史数据
  selectWeatherDataByPage: string;
}

export const apis: Apis = {
  selectDeviceAllotByPage: '/business/deviceAllot/selectDeviceAllotByPage',

  selectSoilInfoByPage: soilUrl + 'selectSoilInfoByPage',
  updateSoilInfo: soilUrl + 'updateSoilInfo',
  selectProjectSoilByList: soilUrl + 'selectProjectSoilByList',
  selectSoilInfoByDeviceNumber: soilUrl + 'selectSoilInfoByDeviceNumber',
  updateSoilInfoConf: soilUrl + 'updateSoilInfoConf',
  updateSoilInfoLocation: soilUrl + 'updateSoilInfoLocation',
  selectSoilDataByPage: soilUrl + 'selectSoilDataByPage',
  selectSoilWarningByPage: soilUrl + 'selectSoilWarningByPage',

  selectInsectInfoByPage: insectUrl + 'selectInsectInfoByPage',
  updateInsectInfo: insectUrl + 'updateInsectInfo',
  selectInsectInfoByDeviceNumber: insectUrl + 'selectInsectInfoByDeviceNumber',
  updateInsectInfoLocation: insectUrl + 'updateInsectInfoLocation',
  selectProjectInsectByList: insectUrl + 'selectProjectInsectByList',
  selectInsectImgByPage: insectUrl + 'selectInsectImgByPage',
  selectInsectDataByPage: insectUrl + 'selectInsectDataByPage',

  selectWaterQualityInfoByPage: waterUrl + 'selectWaterQualityInfoByPage',
  updateWaterQualityInfo: waterUrl + 'updateWaterQualityInfo',
  selectWaterQualityInfoByDeviceNumber: waterUrl + 'selectWaterQualityInfoByDeviceNumber',
  updateWaterQualityInfoLocation: waterUrl + 'updateWaterQualityInfoLocation',
  selectProjectWaterQualityByList: waterUrl + 'selectProjectWaterQualityByList',
  selectWaterQualityDataByPage: waterUrl + 'selectWaterQualityDataByPage',

  selectWeatherInfoByPage: weatherUrl + 'selectWeatherInfoByPage',
  updateWeatherInfo: weatherUrl + 'updateWeatherInfo',
  selectWeatherInfoByDeviceNumber: weatherUrl + 'selectWeatherInfoByDeviceNumber',
  updateWeatherInfoLocation: weatherUrl + 'updateWeatherInfoLocation',
  selectProjectWeatherByList: weatherUrl + 'selectProjectWeatherByList',
  selectWeatherDataByPage: weatherUrl + 'selectWeatherDataByPage'
}
