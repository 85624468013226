import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  // {
  //   path: '/patrol',
  //   name: 'patrol',
  //   meta: {
  //     title: '品质巡查'
  //   },
  //   component: () => import('@/components/menuPage/Index.vue'),
  //   children: [
  //     {
  //       path: 'patrolTask',
  //       name: 'patrolTaskList',
  //       meta: {
  //         title: '巡查任务',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/patrol/task/List.vue')
  //     },
  //     {
  //       path: 'patrolIssue',
  //       meta: {
  //         title: '巡查问题',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/patrol/issue/List.vue')
  //     },
  //     {
  //       path: 'patrolPoint',
  //       name: 'patrolPointList',
  //       meta: {
  //         title: '巡查点位',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/patrol/point/List.vue')
  //     },
  //     {
  //       path: 'patrolElement',
  //       name: 'patrolElementList',
  //       meta: {
  //         title: '巡查要素',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/patrol/element/List.vue')
  //     },
  //     {
  //       path: 'patrolExport',
  //       meta: {
  //         title: '我的导出',
  //         hasBack: false
  //       },
  //       component: () => import('@/views/patrol/export/Index.vue')
  //     }
  //   ]
  // },
  {
    path: '/patrol/patrolTask',
    name: 'patrolTaskList',
    meta: {
      title: '巡查任务',
      hasBack: false
    },
    component: () => import('@/views/patrol/task/List.vue')
  },
  {
    path: '/patrol/patrolIssue',
    meta: {
      title: '巡查问题',
      hasBack: false
    },
    component: () => import('@/views/patrol/issue/List.vue')
  },
  {
    path: '/patrol/patrolPoint',
    name: 'patrolPointList',
    meta: {
      title: '巡查点位',
      hasBack: false
    },
    component: () => import('@/views/patrol/point/List.vue')
  },
  {
    path: '/patrol/patrolElement',
    name: 'patrolElementList',
    meta: {
      title: '巡查要素',
      hasBack: false
    },
    component: () => import('@/views/patrol/element/List.vue')
  },
  {
    path: '/patrol/patrolExport',
    meta: {
      title: '我的导出',
      hasBack: false
    },
    component: () => import('@/views/patrol/export/Index.vue')
  },
  {
    path: '/patrol/patrolTask/add',
    name: 'patrolTaskAdd',
    meta: {
      title: '新增巡查任务'
    },
    component: () => import('@/views/patrol/task/Add.vue')
  },
  {
    path: '/patrol/patrolTask/planDetail:id',
    name: 'patrolTaskPlanDetail',
    meta: {
      title: '执行记录'
    },
    component: () => import('@/views/patrol/task/Detail.vue')
  },
  {
    path: '/patrol/patrolTask/planDetail:id/detail:taskId',
    name: 'patrolTaskDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/patrol/task/TaskDetail.vue')
  },
  {
    path: '/patrol/patrolIssue/detail:issueId',
    name: 'patrolIssueDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/patrol/issue/Detail.vue')
  },
  {
    path: '/patrol/patrolPoint/add',
    name: 'patrolPointAdd',
    meta: {
      title: '新增点位'
    },
    component: () => import('@/views/patrol/point/Add.vue')
  },
  {
    path: '/patrol/patrolPoint/edit:id',
    name: 'patrolPointEdit',
    meta: {
      title: '编辑点位'
    },
    component: () => import('@/views/patrol/point/Add.vue')
  },
  {
    path: '/patrol/patrolPoint/detail:id',
    name: 'patrolPointDetail',
    meta: {
      title: '巡查点位详情'
    },
    component: () => import('@/views/patrol/point/Detail.vue')
  },
  {
    path: '/patrol/patrolElement/add',
    name: 'patrolElementAdd',
    meta: {
      title: '新增要素'
    },
    component: () => import('@/views/patrol/element/Add.vue')
  },
  {
    path: '/patrol/patrolElement/edit:elementId',
    name: 'patrolElementEdit',
    meta: {
      title: '编辑要素'
    },
    component: () => import('@/views/patrol/element/Add.vue')
  },
  {
    path: '/patrol/patrolElement/detail:elementId',
    name: 'patrolElementDetail',
    meta: {
      title: '巡查要素详情'
    },
    component: () => import('@/views/patrol/element/Detail.vue')
  }
]

export default router
