// 数据字典
const url = '/business/yhDic/'
export interface Apis {
  selectDicByPage: string;
  selectDicTypeByList: string;
  insertDic: string;
  updateDic: string;
  selectDicByDicId: string;
}

export const apis: Apis = {
  selectDicByPage: url + 'selectDicByPage',
  selectDicTypeByList: url + 'selectDicTypeByList',
  insertDic: url + 'insertDic',
  updateDic: url + 'updateDic',
  selectDicByDicId: url + 'selectDicByDicId'
}
