import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  // {
  //   path: '/ecologyMonitor',
  //   name: 'ecologyMonitor',
  //   meta: {
  //     title: '养护生态'
  //   }
  // },
  {
    path: '/ecologyMonitor/overview',
    name: 'ecologyOverview',
    meta: {
      title: '概览',
      hasBack: false
    },
    component: () => import('@/components/overview/Index.vue')
  },
  {
    path: '/ecologyMonitor/soilMonitor',
    name: 'soilMonitor',
    component: () => import('@/views/ecologyMonitor/SoilMonitor.vue'),
    meta: {
      title: '土壤监测',
      hasBack: false
    }
  },
  {
    path: '/ecologyMonitor/waterMonitor',
    name: 'waterMonitor',
    props: true,
    component: () => import('@/views/ecologyMonitor/WaterMonitor.vue'),
    meta: {
      title: '水质监测',
      hasBack: false
    }
  },
  {
    path: '/ecologyMonitor/weatherMonitor',
    name: 'weatherMonitor',
    props: true,
    component: () => import('@/views/ecologyMonitor/WeatherMonitor.vue'),
    meta: {
      title: '环境监测',
      hasBack: false
    }
  },
  {
    path: '/ecologyMonitor/insectMonitor',
    name: 'insectMonitor',
    props: true,
    component: () => import('@/views/ecologyMonitor/InsectMonitor.vue'),
    meta: {
      title: '智慧虫情',
      hasBack: false
    }
  },
  {
    path: '/ecologyMonitor/device',
    name: 'ecologyDevice',
    props: true,
    component: () => import('@/views/ecologyMonitor/Device.vue'),
    meta: {
      title: '生态感知设备',
      hasBack: false
    }
  },
  // 设备详情
  {
    name: 'ecologyDeviceDetail',
    path: '/ecologyMonitor/device/deviceDetail',
    component: () => import('@/views/ecologyMonitor/DeviceDetail.vue'),
    meta: {
      title: '查看'
    }
  },
  // 设备历史数据-土壤监测
  {
    name: 'deviceSoilHistory',
    path: '/ecologyMonitor/device/deviceSoilHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/SoilHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  // 土壤监测历史数据
  {
    name: 'soilHistory',
    path: '/ecologyMonitor/soilMonitor/soilHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/SoilHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  // 设备历史数据-水质监测
  {
    name: 'deviceWaterHistory',
    path: '/ecologyMonitor/device/deviceWaterHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/WaterHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  // 水质监测历史数据
  {
    name: 'waterHistory',
    path: '/ecologyMonitor/waterMonitor/waterHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/WaterHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  // 设备历史数据-环境监测
  {
    name: 'deviceWeatherHistory',
    path: '/ecologyMonitor/device/deviceWeatherHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/WeatherHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  // 环境监测历史数据
  {
    name: 'weatherHistory',
    path: '/ecologyMonitor/weatherMonitor/weatherHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/WeatherHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  // 设备历史数据-智慧虫情
  {
    name: 'deviceInsectHistory',
    path: '/ecologyMonitor/device/deviceInsectHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/InsectHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  // 智慧虫情历史数据
  {
    name: 'insectHistory',
    path: '/ecologyMonitor/insectMonitor/insectHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/InsectHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  // 智慧虫情历史监测
  {
    name: 'insectMonitorHistory',
    path: '/ecologyMonitor/insectMonitor/insectMonitorHistory:deviceNumber',
    component: () => import('@/views/ecologyMonitor/InsectMonitorHistory.vue'),
    meta: {
      title: '历史监测'
    }
  }
]

export default router
