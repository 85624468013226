import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/planReport',
    name: 'PlanReport',
    meta: { title: '养护计划' },
    component: () => import('@/views/maintenancePlan/Index.vue')
  },
  {
    path: '/planReport/add',
    name: 'PlanReportAdd',
    meta: { title: '新增养护计划' },
    component: () => import('@/views/maintenancePlan/Add.vue')
  },
  {
    path: '/planReport/add:planId',
    name: 'PlanReportEdit',
    meta: { title: '修改养护计划' },
    component: () => import('@/views/maintenancePlan/Add.vue')
  },
  {
    path: '/planReport/detail:planId',
    name: 'PlanReportDetail',
    meta: { title: '查看养护计划' },
    component: () => import('@/views/maintenancePlan/Detail.vue')
  },
  // {
  //   path: '/planReport',
  //   name: 'PlanReport',
  //   component: () => import('@/views/operationmanage/PlanReport.vue'),
  //   meta: {
  //     title: '计划汇报'
  //   }
  // },
  {
    path: '/planReport/WeekPlanAdd',
    name: 'WeekPlanAdd',
    component: () => import('@/views/operationmanage/planReportAdd/WeekPlanAdd.vue'),
    meta: {
      title: '周工作计划新增'
    }
  },
  {
    path: '/planReport/Edit:reportId&type=:type',
    name: 'WeekPlanEdit',
    component: () => import('@/views/operationmanage/planReportAdd/WeekPlanAdd.vue'),
    meta: {
      title: '周工作计划编辑'
    }
  },
  {
    path: '/planReport/WeekBerichtAdd',
    name: 'WeekBerichtAdd',
    component: () => import('@/views/operationmanage/planReportAdd/WeekPlanAdd.vue'),
    meta: {
      title: '周工作汇报新增'
    }
  },
  {
    path: '/planReport/Edit:reportId&type=:type',
    name: 'WeekBerichtEdit',
    component: () => import('@/views/operationmanage/planReportAdd/WeekPlanAdd.vue'),
    meta: {
      title: '周工作汇报编辑'
    }
  },
  {
    path: '/planReport/MonthPlanAdd',
    name: 'MonthPlanAdd',
    component: () => import('@/views/operationmanage/planReportAdd/MonthPlanAdd.vue'),
    meta: {
      title: '月工作计划新增'
    }
  },
  {
    path: '/planReport/Edit:reportId&type=:type',
    name: 'MonthPlanEdit',
    component: () => import('@/views/operationmanage/planReportAdd/MonthPlanAdd.vue'),
    meta: {
      title: '月工作计划编辑'
    }
  },
  {
    path: '/planReport/detail:reportId',
    name: 'PlanReportDetail',
    component: () => import('@/views/operationmanage/PlanDetail.vue'),
    meta: {
      title: '计划汇报详情'
    }
  }
]

export default router
