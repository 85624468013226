const url = '/business/yhPicture/'
export interface Apis {
  // 品质巡查-全部
  selectQualityInspectionAll: string;
  // 品质巡查-项目
  selectQualityInspectionProject: string;
  // 作业工单-全部
  selectHomeworkOrderAll: string;
  // 作业工单-项目
  selectHomeworkOrderProject: string;
  // 用工签到-全部
  selectEmployeeCheckAll: string;
  // 用工签到-项目
  selectEmploymentCheckProject: string;
  // 图片瀑布流
  selectPictureWaterfallFlow: string;
  // 图片瀑布流时间
  selectPictureWaterfallFlowDate: string;
  // 设备统计-全部
  selectEquipmentStatisticsAll: string;
  // 设备清单-项目
  selectEquipmentListProject: string;
}
export const apis: Apis = {
  // 品质巡查-全部
  selectQualityInspectionAll: url + 'selectQualityInspectionAll',
  // 品质巡查-项目
  selectQualityInspectionProject: url + 'selectQualityInspectionProject',
  // 作业工单-全部
  selectHomeworkOrderAll: url + 'selectHomeworkOrderAll',
  // 作业工单-项目
  selectHomeworkOrderProject: url + 'selectHomeworkOrderProject',
  // 用工签到-全部
  selectEmployeeCheckAll: url + 'selectEmployeeCheckAll',
  // 用工签到-项目
  selectEmploymentCheckProject: url + 'selectEmploymentCheckProject',
  // 图片瀑布流
  selectPictureWaterfallFlow: url + 'selectPictureWaterfallFlow',
  // 图片瀑布流
  selectPictureWaterfallFlowDate: url + 'selectPictureWaterfallFlowDate',
  // 设备统计-全部
  selectEquipmentStatisticsAll: url + 'selectEquipmentStatisticsAll',
  // 设备清单-项目
  selectEquipmentListProject: url + 'selectEquipmentListProject'
}
