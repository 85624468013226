
import { Component, Vue, Watch } from 'vue-property-decorator'
import MenuNavigation from './menu/MenuNavigation.vue'
import LeftMenu from './menu/Left.vue'

@Component({
  components: {
    LeftMenu,
    MenuNavigation
  }
})
export default class Desk extends Vue {
  private menus: any = []

  get keepAlive () {
    return this.$store.state.keepAlive
  }

  get menuList () {
    return this.$store.state.menuList
  }

  // 一张图不展示左侧菜单
  get isHasMenu () {
    return this.$route.path.indexOf('/grid') === -1
  }

  get menuIndex () {
    return this.$store.state.menuIndex
  }

  @Watch('menuIndex', { immediate: true })
  menuIndexChange (val: any) {
    this.menus = this.menuList[+val].childNode || []
  }

  // 跨菜单路径
  @Watch('$route', { immediate: true })
  routeChange (val: any) {
    const menuIndex = this.menuList.findIndex((item: any) => {
      return JSON.stringify(item).indexOf('/' + val.path.split('/')[1]) !== -1
    })
    if (menuIndex > -1) {
      this.$store.commit('updateMenuIndex', menuIndex)
    }
  }
}
