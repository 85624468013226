// 定额库字段
const url1 = '/business/calculateLibraryField/'
// 定额库
const url2 = '/business/calculateLibrary/'
// 人工定额库
const url3 = '/business/calculateLibraryUser/'
// 标前测算
const url4 = '/business/calculateFront/'
export interface Apis {
  /** 新增定额库字段 */
  insertCalculateLibraryField: string;
  /** 编辑定额库字段 */
  updateCalculateLibraryField: string;
  /** 物理删除定额库字段 */
  deleteDataCalculateLibraryField: string;
  /** 查询定额库字段列表 */
  selectCalculateLibraryFieldByList: string;

  /** 新增定额库 */
  insertCalculateLibrary: string;
  /** 修改定额库 */
  updateCalculateLibrary: string;
  /** 删除定额库 */
  deleteCalculateLibrary: string;
  /** 查询定额库详情 */
  selectCalculateLibraryByLibId: string;
  /** 分页查询定额库列表 */
  selectCalculateLibraryByPage: string;

  /** 新增人工定额库 */
  insertCalculateLibraryUser: string;
  /** 修改人工定额库 */
  updateCalculateLibraryUser: string;
  /** 删除人工定额库 */
  deleteCalculateLibraryUser: string;
  /** 查询人工定额库详情 */
  selectCalculateLibraryUserByLibId: string;
  /** 分页查询人工定额库列表 */
  selectCalculateLibraryUserByPage: string;

  /** 新增标前测算 */
  insertCalculateFront: string;
  /** 删除标前测算 */
  deleteCalculateFront: string;
  /** 查询标前测算详情 */
  selectCalculateFrontByFrontId: string;
  /** 分页查询标前测算列表 */
  selectCalculateFrontByPage: string;
  /** 查询定额库镜像详情 */
  selectCalculateLibraryImageByLibId: string;
  /** 查询人工定额库镜像详情 */
  selectCalculateLibraryUserImageByLibId: string;
}
export const apis: Apis = {
  insertCalculateLibraryField: url1 + 'insertCalculateLibraryField',
  updateCalculateLibraryField: url1 + 'updateCalculateLibraryField',
  deleteDataCalculateLibraryField: url1 + 'deleteDataCalculateLibraryField',
  selectCalculateLibraryFieldByList: url1 + 'selectCalculateLibraryFieldByList',
  insertCalculateLibrary: url2 + 'insertCalculateLibrary',
  updateCalculateLibrary: url2 + 'updateCalculateLibrary',
  deleteCalculateLibrary: url2 + 'deleteCalculateLibrary',
  selectCalculateLibraryByLibId: url2 + 'selectCalculateLibraryByLibId',
  selectCalculateLibraryByPage: url2 + 'selectCalculateLibraryByPage',
  insertCalculateLibraryUser: url3 + 'insertCalculateLibraryUser',
  updateCalculateLibraryUser: url3 + 'updateCalculateLibraryUser',
  deleteCalculateLibraryUser: url3 + 'deleteCalculateLibraryUser',
  selectCalculateLibraryUserByLibId: url3 + 'selectCalculateLibraryUserByLibId',
  selectCalculateLibraryUserByPage: url3 + 'selectCalculateLibraryUserByPage',
  insertCalculateFront: url4 + 'insertCalculateFront',
  deleteCalculateFront: url4 + 'deleteCalculateFront',
  selectCalculateFrontByFrontId: url4 + 'selectCalculateFrontByFrontId',
  selectCalculateFrontByPage: url4 + 'selectCalculateFrontByPage',
  selectCalculateLibraryImageByLibId: url4 + 'selectCalculateLibraryImageByLibId',
  selectCalculateLibraryUserImageByLibId: url4 + 'selectCalculateLibraryUserImageByLibId'
}
