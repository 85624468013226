import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/maintainJobMonitor',
    name: 'maintainJobMonitor',
    // component: () => import('@/components/menuPage/Index.vue'),
    meta: {
      title: '养护作业监控'
    }
    // children: [
    //   {
    //     path: 'overview',
    //     name: 'jobOverview',
    //     meta: {
    //       title: '概览',
    //       hasBack: false
    //     },
    //     component: () => import('@/components/overview/Index.vue')
    //   },
    // ]
  },
  {
    path: '/maintainJobMonitor/mowRobot',
    name: 'mowRobot',
    component: () => import('@/views/maintainJobMonitor/MowRobot.vue'),
    meta: {
      title: '割草机器人',
      hasBack: false
    }
  },
  {
    path: '/maintainJobMonitor/device',
    name: 'jobDevice',
    props: true,
    component: () => import('@/views/maintainJobMonitor/Device.vue'),
    meta: {
      title: '智能作业设备',
      hasBack: false
    }
  },
  // 设备详情
  {
    name: 'jobDeviceDetail',
    path: '/maintainJobMonitor/device/deviceDetail',
    component: () => import('@/views/maintainJobMonitor/DeviceDetail.vue'),
    meta: {
      title: '查看'
    }
  },
  // 割草机器人详情&控制台
  {
    name: 'deviceMowRobotDetail',
    path: '/maintainJobMonitor/mowRobot/deviceMowRobotDetail',
    component: () => import('@/views/maintainJobMonitor/MowRobotDetail.vue'),
    meta: {
      title: '设备详情'
    }
  }
]

export default router
