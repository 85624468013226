export interface Apis {
  // 养护天数
  selectRunDayselectRunDay: string;
  // 今日事项
  selectTodayEvent: string;
  // 劳务管理
  selectAttendance: string;
  // 工单作业
  selectWorkOrder: string;
  // 巡查管理
  selectWo: string;
  // 物料管理
  selectMateriel: string;
  // 台班管理
  selectMachinery: string;
}
export const apis: Apis = {
  selectRunDayselectRunDay: '/business/yhOverview/selectRunDayselectRunDay',
  selectTodayEvent: '/business/yhOverview/selectTodayEvent',
  selectAttendance: '/business/yhOverview/selectAttendance',
  selectWorkOrder: '/business/yhOverview/selectWorkOrder',
  selectWo: '/business/yhOverview/selectWo',
  selectMateriel: '/business/yhOverview/selectMateriel',
  selectMachinery: '/business/yhOverview/selectMachinery'

}
