// 智慧监控
const monitorlUrl = '/business/cameraDemoInfo/'
export interface Apis {
  /* 智慧监控 */
  // 分页查询智慧监控列表
  selectCameraDemoInfoByPage: string;
  // 批量分配智慧监控
  updateCameraDemoInfo: string;
  // 查询智慧监控项目列表
  selectProjectCameraDemoByList: string;
  // 查询智慧监控详情
  selectCameraDemoInfoByDeviceNumber: string;
  // 新增监控
  insertCameraDemoInfo: string;
  // 修改智慧监信息
  updateCameraDemoData: string;
  // 修改打点
  updateCameraDemoInfoLocation: string;
}

export const apis: Apis = {
  selectCameraDemoInfoByPage: monitorlUrl + 'selectCameraDemoInfoByPage',
  updateCameraDemoInfo: monitorlUrl + 'updateCameraDemoInfo',
  insertCameraDemoInfo: monitorlUrl + 'insertCameraDemoInfo',
  selectProjectCameraDemoByList: monitorlUrl + 'selectProjectCameraDemoByList',
  selectCameraDemoInfoByDeviceNumber: monitorlUrl + 'selectCameraDemoInfoByDeviceNumber',
  updateCameraDemoData: monitorlUrl + 'updateCameraDemoData',
  updateCameraDemoInfoLocation: monitorlUrl + 'updateCameraDemoInfoLocation'
}
