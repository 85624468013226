// 项目管理
const url = '/business/yhProject/'
export interface Apis {
  // 分页查询项目信息列表
  selectYhProjectByPage: string;
  // 正常项目列表
  selectYhProjectByList: string;
  // 全部项目列表
  selectYhProjectAllByList: string;
  // 新增项目信息
  insertYhProject: string;
  // 修改项目信息
  updateYhProject: string;
  // 项目信息详情
  selectYhProjectByProjectId: string;
  // 项目人员列表
  selectYhProjectUserByList: string;
  // 根据项目绑定人员
  insertYhProjectByProjectId: string;
  // 删除项目绑定人员
  deleteDataYhProjectUser: string;
  // 获取项目围栏信息
  selectYhProjectMapByProjectId: string;
  // 批量新增电子围栏
  insertAllYhLocation: string;
  // 查询电子围栏
  selectYhLocationByList: string;
  // 修改中心点
  updateYhProjectMap: string;

  // 老saas
  insertProject: string;
  updateProject: string;
  deleteProject: string;
  selectProjectById: string;
  /** 查询项目列表 */
  selectProjectByList: string;
  selectProjectByPage: string;
  // 项目人员管理
  insertProjectUser: string;
  deleteDataProjectUser: string;
  selectProjectUserByList: string;
  // 项目区域管理
  insertProjectArea: string;
  updateProjectArea: string;
  deleteDataProjectArea: string;
  selectProjectAreaByList: string;
  // 电子围栏
  insertProjectLocation: string;
  deleteProjectLocation: string;
  /** 根据fenceCode删除电子围栏 */
  deleteLocationByFenceCode: string;
  selectProjectAreaById: string;
  projectLocationList: string;
  /** 查询项目下所有区域的电子围栏列表 */
  selectProjectAreaLocations: string;
  /** 查询项目及所有区域的电子围栏列表 */
  selectProjectAreaLocation: string;
  /** 街道接口 */
  selectVillageList: string;
  // 分页查询工人考勤记录列表
  selectYhWorkerAttendanceByPage: string;
  // 分页考勤统计(按时间段)
  selectAttendanceStatisticsByTimeInterval: string;
  // 分页查询工人考勤记录列表
  selectMplAttendanceList: string;
  // 项目区域
  // 新增
  insertYhProjectArea: string;
  // 修改
  updateYhProjectArea: string;
  // 删除
  deleteYhProjectArea: string;
  // 项目区域详情
  selectYhProjectAreaByProjectAreaId: string;
  // 查询项目区域列表
  selectYhProjectAreaByList: string;

  // 分页查询项目业主信息列表
  selectYhOwnerApplyByPage: string;
  // 状态更新
  updateYhOwnerApplyStatus: string;
  // 删除项目业主信息
  deleteYhOwnerApply: string;
  // 新增项目业主信息
  insertYhOwnerApply: string;
  // 查询业主申请二维码
  selectOwnerApplyQRCode: string;
  selectProjectElectronicFence: string;

}

export const apis: Apis = {
  // 老Saas
  insertProject: '/business/project/insertProject',
  updateProject: '/business/project/updateProject',
  deleteProject: '/business/project/deleteProject',
  selectProjectById: '/business/project/selectProjectById',
  selectProjectByList: '/business/project/selectProjectByList',
  selectProjectByPage: '/business/project/selectProjectByPage',
  // 项目人员管理
  insertProjectUser: '/business/projectUser/insertProjectUser',
  deleteDataProjectUser: '/business/projectUser/deleteDataProjectUser',
  selectProjectUserByList: '/business/projectUser/selectProjectUserByList',
  // 项目区域管理
  insertProjectArea: '/business/projectArea/insertProjectArea',
  updateProjectArea: '/business/projectArea/updateProjectArea',
  deleteDataProjectArea: '/business/projectArea/deleteDataProjectArea',
  selectProjectAreaByList: '/business/projectArea/selectProjectAreaByList',
  // 电子围栏
  insertProjectLocation: '/business/projectArea/insertProjectLocation',
  deleteProjectLocation: '/business/projectArea/deleteProjectLocation',
  deleteLocationByFenceCode: '/business/projectArea/deleteLocationByFenceCode',
  selectProjectAreaById: '/business/projectArea/selectProjectAreaById',
  // 查询电子围栏列表
  projectLocationList: '/business/projectArea/selectProjectLocationList',
  // 查询区域电子围栏列表
  selectProjectAreaLocations: '/business/projectArea/selectProjectAreaLocations',
  selectProjectAreaLocation: '/business/projectArea/selectProjectAreaLocation',
  /** 街道接口 */
  selectVillageList: '/business/project/selectVillageList',
  // 云养护
  selectYhProjectByPage: url + 'selectYhProjectByPage',
  selectYhProjectByList: url + 'selectYhProjectByList',
  selectYhProjectAllByList: url + 'selectYhProjectAllByList',
  insertYhProject: url + 'insertYhProject',
  updateYhProject: url + 'updateYhProject',
  selectYhProjectByProjectId: url + 'selectYhProjectByProjectId',
  selectYhProjectUserByList: url + 'selectYhProjectUserByList',
  insertYhProjectByProjectId: url + 'insertYhProjectByProjectId',
  deleteDataYhProjectUser: url + 'deleteDataYhProjectUser',
  selectYhProjectMapByProjectId: url + 'selectYhProjectMapByProjectId',
  insertAllYhLocation: '/business/yhLocation/insertAllYhLocation',
  selectYhLocationByList: '/business/yhLocation/selectYhLocationByList',
  updateYhProjectMap: url + 'updateYhProjectMap',
  selectYhWorkerAttendanceByPage: '/business/yhWorkAttendance/selectYhWorkerAttendanceByPage',
  selectAttendanceStatisticsByTimeInterval: '/business/yhWorkAttendance/selectAttendanceStatisticsByTimeInterval',
  selectMplAttendanceList: '/business/yhWorkAttendance/selectMplAttendanceList',
  insertYhProjectArea: '/business/yhProjectArea/insertYhProjectArea',
  updateYhProjectArea: '/business/yhProjectArea/updateYhProjectArea',
  deleteYhProjectArea: '/business/yhProjectArea/deleteYhProjectArea',
  selectYhProjectAreaByProjectAreaId: '/business/yhProjectArea/selectYhProjectAreaByProjectAreaId',
  selectYhProjectAreaByList: '/business/yhProjectArea/selectYhProjectAreaByList',

  selectYhOwnerApplyByPage: '/business/yhOwnerApply/selectYhOwnerApplyByPage',
  updateYhOwnerApplyStatus: '/business/yhOwnerApply/updateYhOwnerApplyStatus',
  deleteYhOwnerApply: '/business/yhOwnerApply/deleteYhOwnerApply',
  insertYhOwnerApply: '/business/yhOwnerApply/insertYhOwnerApply',
  selectOwnerApplyQRCode: '/business/yhOwnerApply/selectOwnerApplyQRCode',
  selectProjectElectronicFence: '/business/yhPicture/selectProjectElectronicFence'
}
