
import { Component, Vue, Watch } from 'vue-property-decorator'
import MenuNavigation from './menu/MenuNavigation.vue'
import { MenuItem } from '@/types/menu'
import LeftMenu from './menu/Left.vue'

@Component({
  components: {
    LeftMenu,
    MenuNavigation
  }
})
export default class Desk extends Vue {
  private menus: any = []

  get keepAlive () {
    return this.$store.state.keepAlive
  }

  get menuList () {
    return this.$store.state.menuList
  }

  // 一张图不展示左侧菜单
  get isHasMenu () {
    return this.$route.path.indexOf('/grid') === -1
  }

  @Watch('$route', { immediate: true })
  routeChange (val: any) {
    console.log('默认进入左侧最后一级菜单', val.path)
    const index = this.menuList.findIndex((item: MenuItem) => item.url === val.path)
    // 默认进入最后一级页面
    if (index > -1) {
      this.menus = this.menuList[index].childNode || []
      const path = this.menuList[index].childNode[0].childNode ? this.menuList[index].childNode[0].childNode[0].url : this.menuList[index].childNode[0].url
      this.$router.push({
        path: path,
        query: this.$route.query
      })
    } else {
      const index = this.menuList.findIndex((item: MenuItem) => JSON.stringify(item).indexOf(val.path) !== -1)
      if (index > -1) {
        this.menus = this.menuList[index].childNode || []
      }
    }
  }
}
