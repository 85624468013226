import Vue from 'vue'
import VueRouter, { RouteConfig, RawLocation, Route } from 'vue-router'
import { Message } from 'element-ui'
// 整体布局
import Layout from '../views/layout/Layout.vue'
// 工作台
import Desk from '../views/layout/Desk.vue'
// 经营分析
import Manage from '../views/layout/Manage.vue'
// 系统管理
import System from '../views/layout/System.vue'
// 项目信息
import project from './project'
// 工人
import projectWorker from './projectWorker'
// 车辆
import carManage from './carManage'
// 供应商
import suppliermanage from './suppliermanage'
// 劳务
import laborManage from './laborManage'
// 巡查
import inspection from './inspection'
// 资源信息
import rareTrees from './rareTrees'
// 考评
import projectEvaluation from './projectEvaluation'
// 人员智能调度概览-用工管理
import personnelDispatch from './personnelDispatch'
// 资产信息
import material from './material'
// 组织架构-人员
import department from './department'
// 角色管理
import role from './role'
// 养护计划
import operationmanage from './operationmanage'
// 养护日报
import maintenanceDay from './maintenanceDay'
// 农事日历
import farmingCalendar from './farmingCalendar'
// 消息&操作日志
import message from './message'
// 养护生态监控
import ecologyMonitor from './ecologyMonitor'
// 预警监控中心
import earlyWarning from './earlyWarning'
// 安全风险监控
import secureRiskMonitor from './secureRiskMonitor'
// 养护作业监控
import maintainJobMonitor from './maintainJobMonitor'
// 工单作业中心
import workOrder from './workOrder'
// 智慧喷灌
import irrigation from './irrigation'
// 项目移交
import handover from './handover'
// 标前测算
import preBidCalc from './preBidCalc'
// 项目资料汇编
import projectFile from './projectFile'
// 病虫害防治
import pestControl from './pestControl'
// 机械管理
import machineryManage from './machineryManage'
// 项目资料汇编
import seedlingInspection from './seedlingInspection'
// 品质巡查
import patrol from './patrol'
// 网格管理
import grid from './grid'
// 未来住宅智慧灌溉
import smartHouse from './smartHouse'

Vue.use(VueRouter)

/**
 * 配置说明:
 * 1.路由配置，父子路由路径以'/'隔开，如：项目列表页'/projectList',详情'/projectList/detail',以此类推。配置重定向或者backUrl的除外
 * 2.'/'符号用做了截取父级路由路径判断，所以非路径隔断不要使用。如：动态路由前面不要带'/'；
 * 3.meta字段说明（非必填）：
 *   1).title：页面头部展示标题；
 *   2).hasQuery及backUrl用作返回判断:
 *     backUrl当无法通过路由截取父级路径时配置;
 *     hasQuery用作判断父级路由是否携带query参数,如果有则从子路由上截取带入父路由路径;
 */
const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: [
      // 一张图
      {
        name: 'Grid',
        path: '/grid',
        component: Desk,
        children: [
          ...grid
        ]
      },
      {
        name: 'Grid',
        path: '/apicture',
        component: () => import('@/views/apicture/APicture.vue')
      },
      // 项管中心
      {
        path: '/projectMgtCenter',
        name: '/projectMgtCenter',
        component: Desk,
        children: [
          ...project, // 项目信息
          ...projectFile, // 项目汇编
          ...projectWorker, // 工人管理
          ...laborManage, // 班组管理、劳务管理、机械台班、台班劳务
          ...material, // 物料管理
          ...carManage, // 车辆管理
          ...rareTrees, // 苗木管理
          ...suppliermanage, // 供应商管理
          // ...inspection,
          // ...projectEvaluation, // 项目考评
          // ...personnelDispatch,
          // ...operationmanage
          // ...maintenanceDay,
          // ...farmingCalendar,
          // ...workOrder,
          // ...irrigation,
          // ...handover,
          // ...preBidCalc,
          // ...pestControl,
          // ...machineryManage,
          // ...seedlingInspection,
          // ...patrol,
          // ...smartHouse,
          // 项目管理中心-概览
          {
            path: '/workBench',
            name: 'workBench',
            meta: {
              title: '概览'
            },
            component: () => import('@/views/workBench/ProjectItems.vue')
          },
          // {
          //   path: '/workBench',
          //   name: 'workBench',
          //   meta: {
          //     title: '概览'
          //   },
          //   redirect: '/workBench/overviewItems',
          //   component: () => import('@/views/workBench/Basis.vue'),
          //   children: [
          //     {
          //       path: 'overviewItems',
          //       component: () => import('@/components/overview/Index.vue'),
          //       meta: {
          //         title: '公司级',
          //         hasBack: false
          //       }
          //     },
          //     {
          //       path: 'projectItems',
          //       component: () => import('@/views/workBench/ProjectItems.vue'),
          //       meta: {
          //         title: '项目级',
          //         hasBack: false
          //       }
          //     }
          //   ]
          // },
          // {
          //   path: '/project',
          //   name: 'project',
          //   meta: {
          //     title: '概览'
          //   },
          //   component: () => import('@/components/overview/Index.vue')
          // },
          // 养护调度中心-概览
          // {
          //   path: '/curing',
          //   name: 'curing',
          //   meta: {
          //     title: '概览'
          //   },
          //   component: () => import('@/components/overview/Index.vue')
          // },
          // 古树名木保护-概览
          // {
          //   path: '/tree',
          //   name: 'tree',
          //   meta: {
          //     title: '概览'
          //   },
          //   component: () => import('@/components/overview/Index.vue')
          // },
          // 工单作业中心-概览
          {
            path: '/order',
            name: 'order',
            meta: {
              title: '概览'
            },
            component: () => import('@/components/overview/Index.vue')
          }
        ]
      },
      // 精细管养
      {
        path: '/fineMgtConserve',
        name: '/fineMgtConserve',
        component: Desk,
        children: [
          ...farmingCalendar, // 农事日历
          ...operationmanage, // 养护计划
          ...workOrder, // 工单
          ...maintenanceDay, // 日报
          ...patrol, // 巡查
          ...projectEvaluation // 项目考评
        ]
      },
      // 智能感知
      {
        name: 'Intellisense',
        path: '/intellisense',
        component: Desk,
        children: [
          // 养护生态
          ...ecologyMonitor,
          // 安全风险
          ...secureRiskMonitor,
          // 智能作业
          ...maintainJobMonitor,
          // 预警中心
          ...earlyWarning
        ]
      },
      // 应用中心
      {
        name: 'Application',
        path: '/application',
        component: Desk,
        children: [
          // 应用广场
          {
            name: 'square',
            path: '/square',
            meta: {
              title: '应用广场'
            },
            component: () => import('@/views/square/Index.vue')
          },
          // 项目移交
          ...handover,
          // 标前测算
          ...preBidCalc,
          // 病虫害防治
          ...pestControl,
          // 古树名木保护
          {
            path: '/tree',
            name: 'tree',
            meta: {
              title: '概览'
            },
            component: () => import('@/components/overview/Index.vue')
          },
          // 智慧喷灌
          ...irrigation,
          // 人员调度
          ...personnelDispatch,
          // 机械
          ...machineryManage,
          // 苗木盘点
          ...seedlingInspection,
          // 未来住宅智慧灌溉
          ...smartHouse
        ]
      },
      // iframe嵌入
      {
        name: 'homePage',
        path: '/homePage',
        meta: {
          title: '首页概览'
        },
        component: () => import('@/components/overview/Index.vue')
      },
      // {
      //   path: '/desk',
      //   name: 'desk',
      //   component: Desk,
      //   children: [
      //     ...project,
      //     ...projectWorker,
      //     ...carManage,
      //     ...suppliermanage,
      //     ...laborManage,
      //     ...material,
      //     ...inspection,
      //     ...rareTrees,
      //     ...projectEvaluation,
      //     ...personnelDispatch,
      //     ...operationmanage,
      //     ...maintenanceDay,
      //     ...farmingCalendar,
      //     ...workOrder,
      //     ...preBidCalc,
      //     ...projectFile,
      //     ...pestControl,
      //     ...seedlingInspection,
      //     ...patrol,
      //     ...smartHouse,
      //     // 项目管理中心-概览
      //     {
      //       path: '/workBench',
      //       name: 'workBench',
      //       meta: {
      //         title: '概览'
      //       },
      //       redirect: '/workBench/overviewItems',
      //       component: () => import('@/views/workBench/Basis.vue'),
      //       children: [
      //         {
      //           path: 'overviewItems',
      //           component: () => import('@/components/overview/Index.vue'),
      //           meta: {
      //             title: '公司级',
      //             hasBack: false
      //           }
      //         },
      //         {
      //           path: 'projectItems',
      //           component: () => import('@/views/workBench/ProjectItems.vue'),
      //           meta: {
      //             title: '项目级',
      //             hasBack: false
      //           }
      //         }
      //       ]
      //     },
      //     {
      //       path: '/project',
      //       name: 'project',
      //       meta: {
      //         title: '概览'
      //       },
      //       component: () => import('@/components/overview/Index.vue')
      //     },
      //     // 养护调度中心-概览
      //     {
      //       path: '/curing',
      //       name: 'curing',
      //       meta: {
      //         title: '概览'
      //       },
      //       component: () => import('@/components/overview/Index.vue')
      //     },
      //     // 古树名木保护-概览
      //     {
      //       path: '/tree',
      //       name: 'tree',
      //       meta: {
      //         title: '概览'
      //       },
      //       component: () => import('@/components/overview/Index.vue')
      //     },
      //     // 工单作业中心-概览
      //     {
      //       path: '/order',
      //       name: 'order',
      //       meta: {
      //         title: '概览'
      //       },
      //       component: () => import('@/components/overview/Index.vue')
      //     }
      //   ]
      // },
      {
        path: '/manage',
        name: 'manage',
        component: Manage,
        children: [
          // 外部页面入口
          {
            path: '/overviewAnalysis',
            name: 'overview',
            meta: {
              title: '概览'
            },
            component: () => import('@/components/overview/Index.vue')
          }
        ]
      },
      {
        path: '/system',
        name: 'system',
        component: Desk,
        children: [
          ...department,
          ...role,
          ...message,
          {
            path: '/dictionary',
            name: 'dictionary',
            component: () => import('@/views/businessData/Dictionary.vue'),
            meta: {
              title: '数据字典'
            }
          },
          {
            path: '/sysMaintenanceOperationItem',
            name: 'sysMaintenanceOperationItem',
            component: () => import('@/views/sysMaintenanceOperationItem/Index.vue'),
            meta: {
              title: '养护作业子项'
            }
          }
        ]
      },
      {
        name: 'squareDetail',
        path: '/square/squareDetail:id',
        meta: {
          title: '应用详情'
        },
        component: () => import('@/views/square/Detail.vue')
      },
      {
        path: '/userInfo',
        name: 'userInfo',
        component: () => import('@/views/userinfo/Index.vue'),
        meta: {
          title: '账户信息'
        }
      },
      {
        path: '/404',
        component: () => import('@/views/404.vue'),
        meta: {
          title: '404'
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

// 临时修复路由跳转为当前路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location: RawLocation) {
  return (originalPush.call(this, location) as unknown as Promise<Route>).catch((err: object) => err) as Promise<Route>
}
// 临时修复路由跳转为当前路由报错问题
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push (location: RawLocation) {
  return (originalReplace.call(this, location) as unknown as Promise<Route>).catch((err: object) => err) as Promise<Route>
}

// 路由错误处理
router.beforeEach((to, from, next) => {
  if ((to.meta && to.meta.requireLogin !== undefined && !to.meta.requireLogin) || window.sessionStorage.getItem('token')) {
    // 判断是否需要登录或者是否已经登录,不需要登录或者已经登录正常跳转
    if (to.matched.length === 0) {
      // 跳转404
      next('/404')
    } else {
      next() // 如果匹配到正确跳转
    }
  } else {
    Message.error('您还未登录或者登录已过期,请先登录!')
    // 需要登录且未登录,跳转登录页面
    next('/login')
  }
})
export default router
