// 组织架构
const url = '/business/yhDept/'
export interface Apis {
  departmentList: string;
  departmentAdd: string;
  departmentUpdate: string;
  departmentDelete: string;
}

export const apis: Apis = {
  departmentList: url + 'selectYhDeptByList',
  departmentAdd: url + 'insertYhDept',
  departmentUpdate: url + 'updateYhDept',
  departmentDelete: url + 'deleteYhDept'
}
