// 项目考评指标
const url = '/business/yhEvaluationIndex/'
// 项目考评项
const urls = '/business/yhEvaluationTerm/'
// 项目考评等级配置
const urlx = '/business/yhEvaluationGrade/'
// 项目考评记录
const urlc = '/business/yhEvaluation/'
export interface Apis {
  /** 新增项目考评指标 */
  insertYhEvaluationIndex: string;
  /** 修改项目考评指标 */
  updateYhEvaluationIndex: string;
  /** 删除项目考评指标 */
  deleteYhEvaluationIndex: string;
  /** 查询项目考评指标列表 */
  selectYhEvaluationIndexByList: string;
  /** 批量新增项目考评项 */
  insertAllYhEvaluationTerm: string;
  /** 查询项目考评项列表 */
  selectYhEvaluationTermByList: string;
  /** 新增项目考评记录 */
  insertYhEvaluation: string;
  /** 删除项目考评记录 */
  deleteYhEvaluation: string;
  /** 查询项目考评记录详情 */
  selectYhEvaluationByYhEvaluationId: string;
  /** 查询项目考评记录列表 */
  selectYhEvaluationByList: string;
  /** 查询项目巡查得分 */
  selectIssueByScore: string;
  /** 批量保存项目考评等级 */
  insertYhEvaluationGradeList: string;
  /** 查询项目考评等级配置列表 */
  selectYhEvaluationGradeByList: string;
  /** 查询当前项目是否已经有考评 */
  isYhEvaluationMobile: string;
}
export const apis: Apis = {
  insertYhEvaluationIndex: url + 'insertYhEvaluationIndex',
  updateYhEvaluationIndex: url + 'updateYhEvaluationIndex',
  deleteYhEvaluationIndex: url + 'deleteYhEvaluationIndex',
  selectYhEvaluationIndexByList: url + 'selectYhEvaluationIndexByList',
  insertAllYhEvaluationTerm: urls + 'insertAllYhEvaluationTerm',
  selectYhEvaluationTermByList: urls + 'selectYhEvaluationTermByList',
  selectIssueByScore: urlc + 'selectIssueByScore',
  insertYhEvaluation: urlc + 'insertYhEvaluation',
  deleteYhEvaluation: urlc + 'deleteYhEvaluation',
  selectYhEvaluationByYhEvaluationId: urlc + 'selectYhEvaluationByYhEvaluationId',
  selectYhEvaluationByList: urlc + 'selectYhEvaluationByList',
  insertYhEvaluationGradeList: urlx + 'insertYhEvaluationGradeList',
  selectYhEvaluationGradeByList: urlx + 'selectYhEvaluationGradeByList',
  isYhEvaluationMobile: urlc + 'isYhEvaluationMobile'
}
