import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/smartHouse',
    name: 'SmartHouse',
    meta: {
      title: '未来住宅智慧灌溉'
    },
    component: () => import('@/components/menuPage/Index.vue'),
    children: [
      {
        path: 'screen',
        name: 'screen',
        meta: {
          title: '概览',
          hasBack: false
        },
        component: () => import('@/views/smartHouse/screen/Index.vue')
      },
      {
        path: 'realtime',
        name: 'realtime',
        meta: {
          title: '实时监测',
          hasBack: false
        },
        component: () => import('@/views/smartHouse/realtime/List.vue')
      },
      {
        path: 'build',
        name: 'build',
        meta: {
          title: '楼栋管理',
          hasBack: false
        },
        component: () => import('@/views/smartHouse/build/List.vue')
      },
      {
        path: 'device',
        name: 'device',
        meta: {
          title: '设备管理',
          hasBack: false
        },
        component: () => import('@/views/smartHouse/device/Index.vue'),
        children: [
          {
            path: 'soil',
            name: 'soil',
            meta: {
              title: '土壤监测',
              hasBack: false
            },
            component: () => import('@/views/smartHouse/device/soilList.vue')
          },
          {
            path: 'soil',
            name: 'soil',
            meta: {
              title: '智慧水表',
              hasBack: false
            },
            component: () => import('@/views/smartHouse/device/waterList.vue')
          },
          {
            path: 'solenoid',
            name: 'solenoid',
            meta: {
              title: '电磁阀',
              hasBack: false
            },
            component: () => import('@/views/smartHouse/device/solenoidList.vue')
          }
        ]
      },
      {
        path: 'alarm',
        name: 'alarm',
        meta: {
          title: '告警列表',
          hasBack: false
        },
        component: () => import('@/views/smartHouse/alarm/List.vue')
      },
      {
        path: 'task',
        name: 'task',
        meta: {
          title: '喷灌任务',
          hasBack: false
        },
        component: () => import('@/views/smartHouse/task/List.vue')
      },
      {
        path: 'record',
        name: 'record',
        meta: {
          title: '喷灌记录',
          hasBack: false
        },
        component: () => import('@/views/smartHouse/record/List.vue')
      }
    ]
  },
  {
    path: '/smartHouse/realtime/detail',
    name: 'realtimeDetail',
    meta: {
      title: '实时监测',
      hasBack: true
    },
    component: () => import('@/views/smartHouse/realtime/Detail.vue')
  },
  // 设备历史数据-土壤监测
  {
    name: 'smartHouseDeviceSoilHistory',
    path: '/smartHouse/device/deviceSoilHistory:deviceNumber',
    component: () => import('@/views/smartHouse/device/SoilHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    name: 'smartHouseDeviceWaterHistory',
    path: '/smartHouse/device/smartHouseDeviceWaterHistory:deviceNumber',
    component: () => import('@/views/smartHouse/device/waterHistory.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    name: 'smartRecord',
    path: '/smartHouse/record:deviceNumber',
    meta: {
      title: '历史数据'
    },
    component: () => import('@/views/smartHouse/record/List.vue')
  },
  {
    path: '/smartHouse/build/add',
    name: 'addBuild',
    meta: {
      title: '新增楼栋',
      hasBack: true
    },
    component: () => import('@/views/smartHouse/build/addBuild.vue')
  },
  {
    path: '/smartHouse/build/edit',
    name: 'editBuild',
    meta: {
      title: '编辑楼栋',
      hasBack: true
    },
    component: () => import('@/views/smartHouse/build/addBuild.vue')
  },
  {
    path: '/smartHouse/realtime/task',
    name: 'task',
    meta: {
      title: '喷灌任务',
      hasBack: true
    },
    component: () => import('@/views/smartHouse/task/List.vue')
  },
  {
    path: '/smartHouse/realtime/record',
    name: 'record',
    meta: {
      title: '喷灌记录',
      hasBack: true
    },
    component: () => import('@/views/smartHouse/record/List.vue')
  }
]

export default router
