import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/evaluationProject',
    name: 'evaluationProject',
    meta: { title: '项目考评' },
    component: () => import('@/views/evaluation/EvaluationProject.vue')
  },
  {
    path: '/evaluationProject/add',
    name: 'evaluationProjectAdd',
    meta: { title: '新增考评' },
    component: () => import('@/views/evaluation/EvaluationProjectAdd.vue')
  },
  {
    path: '/evaluationProject/view',
    name: 'evaluationProjectDetail',
    meta: { title: '考评详情' },
    component: () => import('@/views/evaluation/EvaluationProjectView.vue')
  },
  {
    path: '/evaluationProject/rule',
    name: 'evaluationRules',
    meta: { title: '考评规则' },
    component: () => import('@/views/evaluation/EvaluationRules.vue')
  }
]

export default router
