// 苗木盘点
const Url = '/business/yhSeedlingCheck/'
// 苗木盘点记录
const Url2 = '/business/yhSeedlingCheckRecord/'

export interface Apis {
  // 分页查询苗木盘点列表
  selectYhSeedlingCheckByPage: string;
  // 新增苗木盘点
  insertYhSeedlingCheck: string;
  // 修改苗木盘点
  updateYhSeedlingCheck: string;
  // 删除苗木盘点
  deleteYhSeedlingCheck: string;
  // 开始盘点
  startYhSeedlingCheck: string;
  // 结束盘点
  endCheckStatus: string;
  // 查询苗木盘点详情
  selectYhSeedlingCheckByCheckId: string;
  // 查询苗木打点列表
  selectYhSeedlingLocationList: string;
  // 分页查询苗木盘点记录列表
  selectYhSeedlingCheckRecordByPage: string;
  // 根据苗木ID查询历史盘点记录列表
  selectSeedlingCheckRecordHisList: string;

  // 查询苗木盘点记录详情
  selectYhSeedlingCheckRecordByCheckRecordId: string;
  // 待盘新增盘点位置
  insertCheckLocation: string;
  // 已盘删除盘点位置
  deleteCompleteCheckLocation: string;
  // 取消盘盈
  cancelCheckRecordLocation: string;
  // 取消盘亏
  cancelCheckRecord: string;

}
export const apis: Apis = {
  selectYhSeedlingCheckByPage: Url + 'selectYhSeedlingCheckByPage',
  insertYhSeedlingCheck: Url + 'insertYhSeedlingCheck',
  updateYhSeedlingCheck: Url + 'updateYhSeedlingCheck',
  deleteYhSeedlingCheck: Url + 'deleteYhSeedlingCheck',
  startYhSeedlingCheck: Url + 'startYhSeedlingCheck',
  endCheckStatus: Url + 'endCheckStatus',
  selectYhSeedlingCheckByCheckId: Url + 'selectYhSeedlingCheckByCheckId',
  selectYhSeedlingLocationList: '/business/yhSeedling/selectYhSeedlingLocationList',
  selectYhSeedlingCheckRecordByPage: Url2 + 'selectYhSeedlingCheckRecordByPage',
  selectSeedlingCheckRecordHisList: Url2 + 'selectSeedlingCheckRecordHisList',
  selectYhSeedlingCheckRecordByCheckRecordId: Url2 + 'selectYhSeedlingCheckRecordByCheckRecordId',
  insertCheckLocation: Url2 + 'insertCheckLocation',
  deleteCompleteCheckLocation: Url2 + 'deleteCompleteCheckLocation',
  cancelCheckRecordLocation: Url2 + 'cancelCheckRecordLocation',
  cancelCheckRecord: Url2 + 'cancelCheckRecord'
}
