// 工单类型
const typeUrl = '/business/workOrderType/'
// 工单
const orderUrl = '/business/workOrder/'
export interface Apis {
  // 查询工单类型列表
  selectWorkOrderTypeByList: string;
  // 新增工单类型
  insertWorkOrderType: string;
  // 修改工单类型
  updateWorkOrderType: string;
  // 查询工单类型详情
  selectWorkOrderTypeByType: string;
  // 删除工单类型
  deleteDataWorkOrderType: string;
  // 启用/禁用工单类型
  onOffWorkOrderType: string;
  // 查询工单反馈候选人
  selectOrderTypeFeedbackUserByList: string;
  // 查询工单审核候选人
  selectOrderTypeApproveUserByList: string;

  // 分页查询工单列表
  selectWorkOrderByPage: string;
  // 新增工单
  insertWorkOrder: string;
  // 查询工单详情
  selectWorkOrderByOrderId: string;

  // 我的工单
  // 转办
  insertThisWorkOrderFlowOwn: string;
  // 反馈/审核
  insertThisWorkOrderFlowTwo: string;
  // 关闭
  insertThisWorkOrderFlowClose: string;

  // 工单
  // 转办
  insertYhWoPass: string;
  // 反馈/审核
  insertWorkOrderFlowTwo: string;
  // 关闭
  insertWorkOrderFlowClose: string;
}
export const apis: Apis = {
  selectWorkOrderTypeByList: typeUrl + 'selectWorkOrderTypeByList',
  insertWorkOrderType: typeUrl + 'insertWorkOrderType',
  updateWorkOrderType: typeUrl + 'updateWorkOrderType',
  selectWorkOrderTypeByType: typeUrl + 'selectWorkOrderTypeByType',
  deleteDataWorkOrderType: typeUrl + 'deleteDataWorkOrderType',
  onOffWorkOrderType: typeUrl + 'onOffWorkOrderType',
  selectOrderTypeFeedbackUserByList: typeUrl + 'selectOrderTypeFeedbackUserByList',
  selectOrderTypeApproveUserByList: typeUrl + 'selectOrderTypeApproveUserByList',

  selectWorkOrderByPage: orderUrl + 'selectWorkOrderByPage',
  insertWorkOrder: orderUrl + 'insertWorkOrder',
  selectWorkOrderByOrderId: orderUrl + 'selectWorkOrderByOrderId',
  insertThisWorkOrderFlowOwn: orderUrl + 'insertThisWorkOrderFlowOwn',
  insertThisWorkOrderFlowTwo: orderUrl + 'insertThisWorkOrderFlowTwo',
  insertThisWorkOrderFlowClose: orderUrl + 'insertThisWorkOrderFlowClose',
  insertYhWoPass: orderUrl + 'insertYhWoPass',
  insertWorkOrderFlowTwo: orderUrl + 'insertWorkOrderFlowTwo',
  insertWorkOrderFlowClose: orderUrl + 'insertWorkOrderFlowClose'
}
