import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/machineryManage',
    name: 'machineryManage',
    meta: {
      title: '机械管理'
    },
    component: () => import('@/components/menuPage/Index.vue'),
    children: [
      {
        path: 'machine',
        name: 'machine',
        meta: {
          title: '机械库',
          hasBack: false
        },
        component: () => import('@/views/machineryManage/machine/List.vue')
      },
      {
        path: 'strategy',
        name: 'strategy',
        meta: {
          title: '保养策略',
          hasBack: false
        },
        component: () => import('@/views/machineryManage/strategy/Index.vue')
      },
      {
        path: 'call',
        name: 'call',
        meta: {
          title: '机械调用',
          hasBack: false
        },
        component: () => import('@/views/machineryManage/call/Index.vue')
      },
      {
        path: 'return',
        name: 'return',
        meta: {
          title: '领还管理',
          hasBack: false
        },
        component: () => import('@/views/machineryManage/return/Index.vue')
      },
      {
        path: 'repair',
        name: 'repair',
        meta: {
          title: '机械报修',
          hasBack: false
        },
        component: () => import('@/views/machineryManage/repair/Index.vue')
      },
      {
        path: 'upkeep',
        name: 'upkeep',
        meta: {
          title: '机械保养',
          hasBack: false
        },
        component: () => import('@/views/machineryManage/upkeep/Index.vue')
      }
    ]
  },
  {
    path: '/machineryManage/machine/add/base',
    name: 'machineAdd',
    meta: {
      title: '机械管理'
    },
    component: () => import('@/views/machineryManage/machine/Add.vue')
  },
  {
    path: '/machineryManage/call/add',
    name: 'callAdd',
    meta: {
      title: '新增机械调用'
    },
    component: () => import('@/views/machineryManage/call/Add.vue')
  },
  {
    path: '/machineryManage/call/edit',
    name: 'callEdit',
    meta: {
      title: '编辑机械调用'
    },
    component: () => import('@/views/machineryManage/call/Add.vue')
  },
  {
    name: 'machineDetail',
    path: '/machineryManage/machine/detail',
    meta: {
      title: '机械库'
    },
    redirect: '/machineryManage/machine/detail/base',
    component: () => import('@/views/machineryManage/machine/Detail.vue'),
    children: [
      {
        path: 'base',
        component: () => import('@/views/machineryManage/machine/Basis.vue'),
        meta: {
          title: '基本信息'
        }
      },
      {
        path: 'repair',
        component: () => import('@/views/machineryManage/machine/RepairRecord.vue'),
        meta: {
          title: '领还记录'
        }
      },
      {
        path: 'takeback',
        component: () => import('@/views/machineryManage/machine/TakeBackRecord.vue'),
        meta: {
          title: '报修记录'
        }
      },
      {
        path: 'keep',
        component: () => import('@/views/machineryManage/machine/UpkeepRecord.vue'),
        meta: {
          title: '保养记录'
        }
      }
    ]
  }
]

export default router
