import { apis as user, Apis as userApi } from '@/api/user'
import { apis as common, Apis as commonApi } from '@/api/common'
import { apis as project, Apis as projectApi } from '@/api/project'
import { apis as worker, Apis as workerApi } from '@/api/worker'
import { apis as role, Apis as roleApi } from '@/api/role'
import { apis as department, Apis as departmentApi } from '@/api/department'
import { apis as personnel, Apis as personnelApi } from '@/api/personnel'
import { apis as userInfo, Apis as userInfoApi } from '@/api/userInfo'
import { apis as messageRecord, Apis as messageRecordApi } from '@/api/messageRecord'
import { apis as messageWarning, Apis as messageWarningApi } from '@/api/messageWarning'
import { apis as bracelet, Apis as braceletApi } from '@/api/bracelet'
import { apis as inspection, Apis as inspectionApi } from '@/api/inspection'
import { apis as seedling, Apis as seedlingApi } from '@/api/seedling'
import { apis as carManage, Apis as carManageApi } from '@/api/carManage'
import { apis as laborManage, Apis as laborManageApi } from '@/api/laborManage'
import { apis as maintenanceDaily, Apis as maintenanceDailyApi } from '@/api/maintenanceDaily'
import { apis as seedlingInspection, Apis as seedlingInspectionApi } from '@/api/seedlingInspection'
import { apis as evaluation, Apis as evaluationApi } from '@/api/evaluation'
import { apis as workcard, Apis as workcardApi } from '@/api/workcard'
import { apis as material, Apis as materialApi } from '@/api/material'
import { apis as calendar, Apis as calendarApi } from '@/api/farmingCalendar'
import { apis as operationmanage, Apis as operationmanageApi } from '@/api/operationmanage'
import { apis as recruitUse, Apis as recruitUseApi } from '@/api/recruitUse'
import { apis as ecologyMonitor, Apis as ecologyMonitorApi } from '@/api/ecologyMonitor'
import { apis as secureRiskMonitor, Apis as secureRiskMonitorApi } from '@/api/secureRiskMonitor'
import { apis as maintainJobMonitor, Apis as maintainJobMonitorApi } from '@/api/maintainJobMonitor'
import { apis as workOrder, Apis as workOrderApi } from '@/api/workOrder'
import { apis as irrigation, Apis as irrigationApi } from '@/api/irrigation'
import { apis as handover, Apis as handoverApi } from '@/api/handover'
import { apis as preBidCalc, Apis as preBidCalcApi } from '@/api/preBidCalc'
import { apis as projectFile, Apis as projectFileApi } from '@/api/projectFile'
import { apis as pestControl, Apis as pestControlApi } from '@/api/pestControl'
import { apis as lowcode, Apis as lowcodeApi } from '@/api/lowcode'
import { apis as machineryManage, Apis as machineryManageApi } from '@/api/machineryManage'
import { apis as businessData, Apis as businessDataApi } from '@/api/businessData'
import { apis as workBench, Apis as workBenchApi } from '@/api/workBench'
import { apis as patrol, Apis as patrolApi } from '@/api/patrol'
import { apis as grid, Apis as gridApi } from '@/api/grid'
import { apis as smartHouse, Apis as smartHouseApi } from '@/api/smartHouse'
import { apis as apicture, Apis as apictureApi } from '@/api/apicture'

export interface Apis {
  user: userApi;
  common: commonApi;
  project: projectApi;
  worker: workerApi;
  role: roleApi;
  department: departmentApi;
  personnel: personnelApi;
  userInfo: userInfoApi;
  messageRecord: messageRecordApi;
  messageWarning: messageWarningApi;
  bracelet: braceletApi;
  inspection: inspectionApi;
  seedling: seedlingApi;
  carManage: carManageApi;
  laborManage: laborManageApi;
  maintenanceDaily: maintenanceDailyApi;
  seedlingInspection: seedlingInspectionApi;
  evaluation: evaluationApi;
  workcard: workcardApi;
  material: materialApi;
  calendar: calendarApi;
  operationmanage: operationmanageApi;
  businessData: businessDataApi;
  recruitUse: recruitUseApi;
  ecologyMonitor: ecologyMonitorApi;
  secureRiskMonitor: secureRiskMonitorApi;
  maintainJobMonitor: maintainJobMonitorApi;
  workOrder: workOrderApi;
  irrigation: irrigationApi;
  handover: handoverApi;
  preBidCalc: preBidCalcApi;
  projectFile: projectFileApi;
  pestControl: pestControlApi;
  lowcode: lowcodeApi;
  machineryManage: machineryManageApi;
  workBench: workBenchApi;
  patrol: patrolApi;
  grid: gridApi;
  smartHouse: smartHouseApi;
  apicture: apictureApi;
}

export const apis: Apis = {
  // *
  user,
  // *
  role,

  // *
  common,
  // *
  project,
  // *
  worker,

  // *
  department,
  // *
  personnel,

  userInfo,
  messageWarning,
  messageRecord,
  bracelet,
  inspection,
  seedling,
  carManage,
  laborManage,
  maintenanceDaily,
  seedlingInspection,
  evaluation,
  workcard,
  material,
  calendar,
  operationmanage,
  // *
  businessData,
  recruitUse,
  ecologyMonitor,
  secureRiskMonitor,
  maintainJobMonitor,
  workOrder,
  irrigation,
  handover,
  preBidCalc,
  projectFile,
  pestControl,
  lowcode,
  machineryManage,
  workBench,
  patrol,
  grid,
  smartHouse,
  apicture
}
