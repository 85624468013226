import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/projectFile',
    name: 'projectFileList',
    meta: { title: '项目资料汇编' },
    component: () => import('@/views/projectFile/List.vue')
  },
  {
    path: '/projectFile/Detail:projectId',
    name: 'projectFileDetail',
    meta: { title: '详情' },
    component: () => import('@/views/projectFile/Detail.vue')
  }
]

export default router
