// 角色
const url = '/business/yhRole/'
export interface Apis {
  // ***
  rolePage: string;
  roleAdd: string;
  roleUpdate: string;
  roleDelete: string;
  roleDetail: string;
  roleMenu: string;
  roleAppMenu: string;
}

export const apis: Apis = {
  rolePage: url + 'selectYhRoleByPage',
  roleAdd: url + 'insertYhRole',
  roleUpdate: url + 'updateYhRole',
  roleDelete: url + 'deleteYhRole',
  roleDetail: url + 'selectYhRoleById',
  roleMenu: url + 'selectYhMenuById',
  roleAppMenu: url + 'selectAppYhMenuById'
}
