// 病害
const url = '/business/yhDiseases/'
// 虫害
const urls = '/business/yhPests/'
export interface Apis{
  // 新增病害
  insertYhDiseases: string;
  // 修改病害
  updateYhDiseases: string;
  // 删除病害
  deleteYhDiseases: string;
  // 查询病害详情
  selectYhDiseasesByDiseasesId: string;
  // 分页查询病害列表
  selectYhDiseasesByPage: string;
  // 新增虫害
  insertYhPests: string;
  // 修改虫害
  updateYhPests: string;
  // 删除虫害
  deleteYhPests: string;
  // 查询虫害详情
  selectYhPestsByPestsId: string;
  // 分页查询虫害列表
  selectYhPestsByPage: string;
}
export const apis: Apis = {
  insertYhDiseases: url + 'insertYhDiseases',
  updateYhDiseases: url + 'updateYhDiseases',
  deleteYhDiseases: url + 'deleteYhDiseases',
  selectYhDiseasesByDiseasesId: url + 'selectYhDiseasesByDiseasesId',
  selectYhDiseasesByPage: url + 'selectYhDiseasesByPage',
  insertYhPests: urls + 'insertYhPests',
  updateYhPests: urls + 'updateYhPests',
  deleteYhPests: urls + 'deleteYhPests',
  selectYhPestsByPestsId: urls + 'selectYhPestsByPestsId',
  selectYhPestsByPage: urls + 'selectYhPestsByPage'
}
