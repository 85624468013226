import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/irrigation',
    meta: {
      title: '智慧模型配置'
    },
    component: () => import('@/views/irrigation/IrrigationManage.vue')
  },
  {
    path: '/irrigation/detail',
    meta: {
      title: '智慧模型配置'
    },
    redirect: '/irrigation/detail/manage',
    component: () => import('@/views/irrigation/IrrigationDetail.vue'),
    children: [
      {
        path: 'manage',
        component: () => import('@/views/irrigation/IrrigationArea.vue'),
        meta: {
          title: '喷灌管理'
        }
      },
      // 土壤监测历史数据
      {
        name: 'irrigationSoilHistory',
        path: '/irrigation/ecologyMonitor/soilMonitor/soilHistory:deviceNumber',
        component: () => import('@/views/ecologyMonitor/SoilHistory.vue'),
        meta: {
          title: '历史数据'
        }
      },
      {
        path: 'plan',
        component: () => import('@/views/irrigation/IrrigationPlan.vue'),
        meta: {
          title: '设置计划'
        }
      },
      {
        path: 'record',
        component: () => import('@/views/irrigation/IrrigationRecord.vue'),
        meta: {
          title: '喷灌记录'
        }
      }
    ]
  },
  {
    path: '/irrigation/addArea',
    name: 'irrigationAddArea',
    component: () => import('@/views/irrigation/irrigationAddArea.vue'),
    meta: {
      title: '新增区域',
      hasQuery: true,
      backUrl: '/irrigation/detail/manage'
    }
  },
  {
    path: '/irrigation/updateArea:id',
    name: 'irrigationUpdateArea',
    component: () => import('@/views/irrigation/irrigationAddArea.vue'),
    meta: {
      title: '编辑区域',
      hasQuery: true,
      backUrl: '/irrigation/detail/manage'
    }
  }
]
export default router
