import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/grid',
    name: 'GridManage',
    meta: {
      title: '网格管理'
    },
    component: () => import('@/views/grid/Index.vue')
  },
  {
    path: '/grid/detail',
    name: 'GridManage',
    meta: {
      title: '网格管理'
    },
    component: () => import('@/views/grid/ProjectGrid.vue')
  }
]

export default router
