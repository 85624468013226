// 车辆管理
// 基础信息
const url = '/business/yhVehicle/'
// 维修信息
const urls = '/business/yhVehicleRepair/'
// 违章信息
const urlx = '/business/yhVehicleViolation/'
export interface Apis {

  // 云养护
  // 基础信息-新增
  insertYhVehicle: string;
  // 基础信息-修改
  updateYhVehicle: string;
  // 基础信息-删除
  deleteYhVehicle: string;
  // 基础信息-查询车辆详情
  selectYhVehicleByVehicleId: string;
  // 基础信息-分页查询车辆列表
  selectYhVehicleByPage: string;
  // 维修信息-新增
  insertYhVehicleRepair: string;
  // 维修信息-修改
  updateYhVehicleRepair: string;
  // 维修信息-删除
  deleteYhVehicleRepair: string;
  // 维修信息-查询车辆详情
  selectYhVehicleRepairByRepairId: string;
  // 维修信息-分页查询车辆列表
  selectYhVehicleRepairByPage: string;
  // 违章信息-新增
  insertYhVehicleViolation: string;
  // 违章信息-修改
  updateYhVehicleViolation: string;
  // 违章信息-删除
  deleteYhVehicleViolation: string;
  // 违章信息-查询车辆详情
  selectYhVehicleViolationByViolationId: string;
  // 违章信息-分页查询车辆列表
  selectYhVehicleViolationByPage: string;
}
export const apis: Apis = {

  // 云养护
  insertYhVehicle: url + 'insertYhVehicle',
  deleteYhVehicle: url + 'deleteYhVehicle',
  updateYhVehicle: url + 'updateYhVehicle',
  selectYhVehicleByVehicleId: url + 'selectYhVehicleByVehicleId',
  selectYhVehicleByPage: url + 'selectYhVehicleByPage',
  insertYhVehicleRepair: urls + 'insertYhVehicleRepair',
  updateYhVehicleRepair: urls + 'updateYhVehicleRepair',
  deleteYhVehicleRepair: urls + 'deleteYhVehicleRepair',
  selectYhVehicleRepairByRepairId: urls + 'selectYhVehicleRepairByRepairId',
  selectYhVehicleRepairByPage: urls + 'selectYhVehicleRepairByPage',
  insertYhVehicleViolation: urlx + 'insertYhVehicleViolation',
  updateYhVehicleViolation: urlx + 'updateYhVehicleViolation',
  deleteYhVehicleViolation: urlx + 'deleteYhVehicleViolation',
  selectYhVehicleViolationByViolationId: urlx + 'selectYhVehicleViolationByViolationId',
  selectYhVehicleViolationByPage: urlx + 'selectYhVehicleViolationByPage'
}
