import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/secureRiskMonitor',
    name: 'secureRiskMonitor',
    meta: {
      title: '安全风险监控'
    }
  },
  {
    path: '/secureRiskMonitor/monitor',
    name: 'monitor',
    component: () => import('@/views/secureRiskMonitor/Monitor.vue'),
    meta: {
      title: '智能监控',
      hasBack: false
    }
  },
  {
    path: '/secureRiskMonitor/device',
    name: 'device',
    props: true,
    component: () => import('@/views/secureRiskMonitor/Device.vue'),
    meta: {
      title: '风险监控设备',
      hasBack: false
    }
  },
  // 设备详情
  {
    name: 'deviceDetail',
    path: '/secureRiskMonitor/device/deviceDetail',
    component: () => import('@/views/secureRiskMonitor/DeviceDetail.vue'),
    meta: {
      title: '查看'
    }
  },
  {
    name: 'deviceAdd',
    path: '/secureRiskMonitor/device/deviceAdd',
    component: () => import('@/views/secureRiskMonitor/DeviceDetail.vue'),
    meta: {
      title: '新增'
    }
  }
]

export default router
