// 接口名定义
const schedulingUrl = '/business/projectScheduling/'
const url = '/business/yhWorker/'
export interface Apis {
  // ***
  insertProjectWorker: string;
  // ***
  updateProjectWorker: string;
  // ***
  deleteProjectWorker: string;
  // ***
  selectProjectWorkerByWorkerId: string;
  // ***
  selectProjectWorkerByList: string;
  // ***
  selectProjectWorkerByPage: string;
  // ***
  // 班组
  // ***
  insertProjectUserTeam: string;
  // ***
  updateProjectUserTeam: string;
  // ***
  deleteProjectUserTeam: string;
  // ***
  selectProjectUserTeamByList: string;
  // 获取工人绑定手环数量
  // ***
  countBoundDevice: string;
  // 排班
  // 分页查询项目排班列表
  selectProjectSchedulingByPage: string;
  // 新增项目排班
  insertAllProjectScheduling: string;
  // 修改项目排班
  updateProjectScheduling: string;
  // 删除项目排班
  deleteDataProjectScheduling: string;
  // 查询项目排班情况
  selectProjectSchedulingByList: string;
  // 查询项目工人列表-下拉
  selectWorkerList: string;
  // 查询项目在岗班次列表-下拉
  selectWorkTimeListByProjectId: string;
  // 查询项目排班详情
  selectProjectSchedulingById: string;
  // 排班日历分页查询
  selectSchedulingCalendarPage: string;
  selectWorkerOnWorkjPage: string;
  selectOffWorkCardPage: string;
  exportWorkerOnWork: string;
  exportOffWorkCard: string;

  // 云养护
  // 分页查询工人列表
  selectYhWorkerByPage: string;
  // 新增工人
  insertYhWorker: string;
  // 修改工人
  updateYhWorker: string;
  // 删除工人
  deleteYhWorker: string;
  // 查询工人详情
  selectYhWorkerByWorkerId: string;
  // 导入工人
  importProjectWorker: string;
}

export const apis: Apis = {
  insertProjectWorker: '/business/projectWorker/insertProjectWorker',
  updateProjectWorker: '/business/projectWorker/updateProjectWorker',
  deleteProjectWorker: '/business/projectWorker/deleteProjectWorker',
  selectProjectWorkerByWorkerId: '/business/projectWorker/selectProjectWorkerByWorkerId',
  selectProjectWorkerByList: '/business/projectWorker/selectProjectWorkerByList',
  selectProjectWorkerByPage: '/business/projectWorker/selectProjectWorkerByPage',
  // 班组
  insertProjectUserTeam: '/business/projectUserTeam/insertProjectUserTeam',
  updateProjectUserTeam: '/business/projectUserTeam/updateProjectUserTeam',
  deleteProjectUserTeam: '/business/projectUserTeam/deleteProjectUserTeam',
  selectProjectUserTeamByList: '/business/projectUserTeam/selectProjectUserTeamByList',
  countBoundDevice: 'business/projectWorker/countBoundDevice',
  // 排班
  selectProjectSchedulingByPage: schedulingUrl + 'selectProjectSchedulingByPage',
  insertAllProjectScheduling: schedulingUrl + 'insertAllProjectScheduling',
  updateProjectScheduling: schedulingUrl + 'updateProjectScheduling',
  deleteDataProjectScheduling: schedulingUrl + 'deleteDataProjectScheduling',
  selectProjectSchedulingByList: schedulingUrl + 'selectProjectSchedulingByList',
  selectWorkerList: schedulingUrl + 'selectWorkerList',
  selectWorkTimeListByProjectId: schedulingUrl + 'selectWorkTimeListByProjectId',
  selectProjectSchedulingById: schedulingUrl + 'selectProjectSchedulingById',
  selectSchedulingCalendarPage: schedulingUrl + 'selectSchedulingCalendarPage',
  // 在岗监测
  selectWorkerOnWorkjPage: '/business/workCard/selectWorkerOnWorkjPage',
  selectOffWorkCardPage: '/business/workCard/selectOffWorkCardPage',
  exportWorkerOnWork: '/business/workCard/exportWorkerOnWork',
  exportOffWorkCard: '/business/workCard/exportOffWorkCard',

  // 云养护
  selectYhWorkerByPage: url + 'selectYhWorkerByPage',
  insertYhWorker: url + 'insertYhWorker',
  updateYhWorker: url + 'updateYhWorker',
  deleteYhWorker: url + 'deleteYhWorker',
  selectYhWorkerByWorkerId: url + 'selectYhWorkerByWorkerId',
  importProjectWorker: url + 'importProjectWorker'
}
