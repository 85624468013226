// 接口名定义
const url = '/business/yhWo/'
const urls = '/business/yhWoNodeRole/'
const urlx = '/business/yhWoIssue/'
export interface Apis {
  // 云养护-巡查
  // 新增巡查任务
  insertYhWo: string;
  // 修改巡查任务
  updateYhWo: string;
  // 删除巡查任务
  deleteYhWo: string;
  // 查询巡查任务详情
  selectYhWoByWoId: string;
  // 分页查询巡查任务列表
  selectYhWoByPage: string;
  // 导出巡查
  exportPatrolData: string;
  // 云养护-巡查设置
  // 设置巡查
  insertAllYhWoNodeRole: string;
  // 巡查设置列表
  selectYhWoNodeRoleByList: string;
  // 反馈候选人
  selectYhFeedbackUserByList: string;
  // 审核候选人
  selectYhApproveUserByList: string;
  // 云养护-巡查问题
  // 新增巡查问题
  insertYhWoIssue: string;
  // 修改巡查问题
  updateYhWoIssue: string;
  // 删除巡查问题
  deleteYhWoIssue: string;
  // 查询巡查问题详情
  selectYhWoIssueByIssueId: string;
  // 分页查询巡查问题列表
  selectYhWoIssueByPage: string;
  // 巡查问题催办
  insertYhWoUrge: string;
  // 巡查问题转办
  insertYhWoPass: string;
  // 巡查问题反馈/审核
  insertYhWoFlow: string;
}

export const apis: Apis = {
  // 云养护
  insertYhWo: url + 'insertYhWo',
  updateYhWo: url + 'updateYhWo',
  deleteYhWo: url + 'deleteYhWo',
  selectYhWoByWoId: url + 'selectYhWoByWoId',
  selectYhWoByPage: url + 'selectYhWoByPage',
  exportPatrolData: url + 'exportPatrolData',
  insertAllYhWoNodeRole: urls + 'insertAllYhWoNodeRole',
  selectYhWoNodeRoleByList: urls + 'selectYhWoNodeRoleByList',
  selectYhFeedbackUserByList: urls + 'selectYhFeedbackUserByList',
  selectYhApproveUserByList: urls + 'selectYhApproveUserByList',
  insertYhWoIssue: urlx + 'insertYhWoIssue',
  updateYhWoIssue: urlx + 'updateYhWoIssue',
  deleteYhWoIssue: urlx + 'deleteYhWoIssue',
  selectYhWoIssueByIssueId: urlx + 'selectYhWoIssueByIssueId',
  selectYhWoIssueByPage: urlx + 'selectYhWoIssueByPage',
  insertYhWoUrge: urlx + 'insertYhWoUrge',
  insertYhWoPass: urlx + 'insertYhWoPass',
  insertYhWoFlow: urlx + 'insertYhWoFlow'
}
