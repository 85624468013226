import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/resource',
    name: 'resource',
    meta: {
      title: '资源信息'
    },
    redirect: '/resource/ordinarySeedlingList',
    component: () => import('@/views/rareTrees/Base.vue'),
    children: [
      {
        path: 'ordinarySeedlingList',
        name: 'ordinarySeedlingList',
        component: () => import('@/views/ordinaryseedling/List.vue'),
        meta: {
          title: '普通苗木',
          hasBack: false
        }
      },
      {
        path: 'rareTreesBase',
        name: 'rareTreesList',
        props: true,
        component: () => import('@/views/rareTrees/List.vue'),
        meta: {
          title: '古树名木',
          hasBack: false
        }
      }
    ]
  },
  // 古树名木
  {
    path: '/resource/rareTreesBase/add',
    name: 'rareTreesAdd',
    component: () => import('@/views/rareTrees/Add.vue'),
    meta: {
      title: '新增'
    }
  },
  {
    path: '/resource/rareTreesBase/edit:seedlingId',
    name: 'rareTreesEdit',
    component: () => import('@/views/rareTrees/Add.vue'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/resource/rareTreesBase/detail:seedlingId',
    name: 'rareTreesDetailList',
    component: () => import('@/views/rareTrees/PlantDetail.vue'),
    meta: {
      title: '详情'
    }
  },
  // 普通苗木
  {
    path: '/resource/ordinarySeedlingList/add',
    name: 'ordinarySeedlingAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/ordinaryseedling/Add.vue')
  },
  {
    path: '/resource/ordinarySeedlingList/update:seedlingId',
    name: 'ordinarySeedlingUpdate',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/ordinaryseedling/Add.vue')
  },
  {
    path: '/resource/ordinarySeedlingList/detail:seedlingId',
    name: 'ordinarySeedlingDetail',
    component: () => import('@/views/ordinaryseedling/PlantDetail.vue'),
    meta: {
      title: '详情'
    }
  }

]

export default router
