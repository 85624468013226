
import { Vue, Component } from 'vue-property-decorator'
@Component
export default class MenuNavigation extends Vue {
  get title () {
    return this.$route.meta ? this.$route.meta.title : ''
  }

  get parentUrl () {
    if (this.$route.meta && this.$route.meta.hasBack !== false) {
      const routeUrl = this.$route.meta.backUrl || this.$route.path.substr(0, this.$route.path.lastIndexOf('/'))
      const routeQuery = this.$route.meta.hasQuery ? this.$route.query || {} : {}
      return routeUrl ? { path: routeUrl, query: routeQuery } : false
    } else {
      return false
    }
  }
}
