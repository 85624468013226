export interface Apis {
  // 养护项目区域列表
  selectProjectAreaYhList: string;
  // 查询区域的点位设施列表
  selectProjectAreaYhPointList: string;
  // 养护项目区域日期列表
  selectProjectAreaYhDateList: string;
  // 养护项目区域日签到点位
  selectProjectAreaYhDatePoint: string;
  // 养护项目区域工单
  selectProjectAreaYhDateOrder: string;
  // 养护项目区域养护标准
  selectProjectAreaYhStandard: string;
  // 项目区域网格详情
  selectGridProjectAreaDetail: string;
  // 养护项目区域工单视图日期列表
  selectProjectAreaYhDateOrderList: string;
  // 养护项目区域工单视图日期列表
  selectYhProjectAreaSumList: string;
  // 查询点位近30次签到列表
  selectPoint30SignList: string;
}
export const apis: Apis = {
  selectProjectAreaYhList: '/business/yhProjectAreaYh/selectProjectAreaYhList',
  selectProjectAreaYhPointList: '/business/yhProjectAreaYh/selectProjectAreaYhPointList',
  selectProjectAreaYhDateList: '/business/yhProjectAreaYh/selectProjectAreaYhDateList',
  selectProjectAreaYhDatePoint: '/business/yhProjectAreaYh/selectProjectAreaYhDatePoint',
  selectProjectAreaYhStandard: '/business/yhProjectAreaYh/selectProjectAreaYhStandard',
  selectGridProjectAreaDetail: '/business/yhProjectAreaYh/selectGridProjectAreaDetail',
  selectProjectAreaYhDateOrder: '/business/yhProjectAreaYh/selectProjectAreaYhDateOrder',
  selectProjectAreaYhDateOrderList: '/business/yhProjectAreaYh/selectProjectAreaYhDateOrderList',
  selectYhProjectAreaSumList: '/business/yhProjectAreaYh/selectYhProjectAreaSumList',
  selectPoint30SignList: '/business/yhProjectAreaYh/selectPoint30SignList'
}
