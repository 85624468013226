// 资源管理表木
const url = '/business/yhSeedling/'

export interface Apis {
  // 云养护
  /** 新增苗木 */
  insertYhSeedling: string;
  /** 修改苗木 */
  updateYhSeedling: string;
  /** 删除苗木 */
  deleteYhSeedling: string;
  /** 查询苗木详情 */
  selectYhSeedlingBySeedlingId: string;
  /** 分页查询苗木列表 */
  selectYhSeedlingByPage: string;
  /** 导出 */
  exportSeedlingData: string;
  /** 导入苗木 */
  importYhSeedling: string;
  /** 导入古树名木 */
  importYhSeedlingAncient: string;
}

export const apis: Apis = {

  // 云养护
  insertYhSeedling: url + 'insertYhSeedling',
  updateYhSeedling: url + 'updateYhSeedling',
  deleteYhSeedling: url + 'deleteYhSeedling',
  selectYhSeedlingBySeedlingId: url + 'selectYhSeedlingBySeedlingId',
  selectYhSeedlingByPage: url + 'selectYhSeedlingByPage',
  exportSeedlingData: url + 'exportSeedlingData',
  importYhSeedling: url + 'importYhSeedling',
  importYhSeedlingAncient: url + 'importYhSeedlingAncient'
}
